/* eslint-disable import/no-cycle */
import { get, has, omitBy } from 'lodash';
import { isPrOrCitizen, actionHandlerWithFeatureFlag } from 'utils';
import {
  classOpsService,
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
} from 'utils/msEndpointConstants';
import { UPDATE_ENQUIRY_DETAILS } from '../models/waitlistModel';
import {
  FETCH_ALL_CLASSES,
  FETCH_ALL_TEACHERS,
  FETCH_CHILDREN_ASSIGN_CLASS,
  FETCH_CHILDREN_DATA,
  FETCH_ALL_CHILD,
  FETCH_CURRENT_CHILDID,
  UPDATE_CLASS_DETAILS_PAGE,
  FETCH_PAST_CHILDREN,
  FETCH_PAST_CHILDREN_SEARCH,
  FETCH_REQ_DOCS,
  CLEAR_UPLOAD_DOCS,
  FETCH_UPLOAD_DOC_URL,
  FETCH_ACTIVITY_LOG,
  FETCH_DOCUMENT_BY_CATEGORY,
  CLEAR_FETCH_ALL_CHILD,
  CLEAR_ALL_CLASSES,
  FETCH_ALL_GUARDIAN,
  CLEAR_ASSIGN_OR_PROMOTE_DETAIL,
  SET_ASSIGN_OR_PROMOTE_DETAIL,
  FETCH_CLASS_DETAIL,
  CLEAR_CLASS_DETAIL,
  FETCH_FORM2_COUNT,
  FETCH_FORM3_COUNT,
  CLEAR_CURRENT_CHILD,
  FIND_ALL_PROGRAMS,
  FETCH_UPDATED_CHILD_PARTICULAR,
  FETCH_CLASS_ACTIVITIES,
  FETCH_CLASS_ACTIVITY_TAGS,
  FETCH_CLASS_ACTIVITY_STICKERS,
  FETCH_CLASS_RESOURCES,
  FETCH_LEARNING_GOALS_OPTIONS_LIST,
  FETCH_FEE_TIER_FOR_CHILD,
  FETCH_EMERGENCY_CONTACT_INFO_ERROR,
  FETCH_EMERGENCY_CONTACT_INFO,
  FETCH_EMERGENCY_CONTACT_INFO_SUCCESS,
  CLEAR_EMERGENCY_CONTACT_INFO,
  SET_UPLOAD_IMAGES,
  FETCH_CHILD_HEALTH_INFO,
  FETCH_CHILD_HEALTH_INFO_SUCCESS,
  FETCH_CHILD_HEALTH_INFO_ERROR,
  UPDATE_CHILD_HEALTH_INFO_REQUEST,
  UPDATE_CHILD_HEALTH_INFO_SUCCESS,
  UPDATE_CHILD_HEALTH_INFO_ERROR,
  REMOVE_ENROLLED_CHILD_DOC_REQUEST,
  REMOVE_ENROLLED_CHILD_DOC_SUCCESS,
  REMOVE_ENROLLED_CHILD_DOC_ERROR,
  FETCH_UPLOAD_DOCS_REQUEST,
  FETCH_UPLOAD_DOCS_SUCCESS,
  FETCH_UPLOAD_DOCS_ERROR,
  UPDATE_DOCS_UPLOADED_BY_TYPE_REQUEST,
  UPDATE_DOCS_UPLOADED_BY_TYPE_SUCCESS,
  UPDATE_DOCS_UPLOADED_BY_TYPE_ERROR,
  REMOVE_ENROLLED_CHILD_DOC_TYPE_REQUEST,
  REMOVE_ENROLLED_CHILD_DOC_TYPE_SUCCESS,
  REMOVE_ENROLLED_CHILD_DOC_TYPE_ERROR,
  FETCH_APPLICABLE_FEE_TIER_FOR_CHILD,
  FETCH_CHILD_FEE_TIER_FOR_CHILD,
  FETCH_UPCOMING_FEE_TIER_FOR_CHILD,
  GET_MARKETING_CONSENT_BY_PARENT_REQUEST,
  GET_MARKETING_CONSENT_BY_PARENT_SUCCESS,
  GET_MARKETING_CONSENT_BY_PARENT_ERROR,
  FETCH_ALL_GUARDIAN_SEARCH,
} from './actionTypes';
import {
  FIND_ALL_CHILD,
  GET_CHILDREN_TO_ASSIGN_CLASS,
  GET_CLASS_MANAGEMENT_LIST,
  GET_CLASS_DETAIL,
  GET_CLASS_LIST,
  GET_CHILDREN_DATA,
  GET_PAST_CHILDREN_DATA,
  ASSIGN_CHILD_TO_CLASS,
  PROMOTE_AND_ASSIGN_CHILD,
  gqlGetUploadedDocument,
  gqlListRequiredDocuments,
  gqlgetDocumentTypeByDocumentCategory,
  gqlgetEnrolmentDocumentURL,
  gqlgetTeacherListOnInput,
  gqlchildByID,
  gqlCreateNewClass,
  gqlGetClassPhotoUploadURL,
  gqlGetChildPhotoUploadURL,
  gqlUpdateClassDetails,
  gqlUpdateChildByFields,
  gqlDeleteClassAndTeachers,
  gqlApproveEnquiryDocument,
  gqlRejectEnquiryDocument,
  gqlMarkEnquiryDocumentAsNotRequired,
  gqlGetDocumentUploadURLByIDDocumentTypeConfig,
  gqlSaveDocument,
  PENDING_WITHDRAWAL_FORM,
  PENDING_PROMOTION_FORM,
  UPDATE_NATIONALITY,
  FIND_ALL_PROGRAM_LIST,
  FIND_CLASS_ACTIVITIES,
  FIND_CLASS_ACTIVITY_TAGS,
  FIND_ALL_STICKER,
  FIND_ALL_CLASS_RESOURCES,
  GET_RESOURCE_FILE_UPLOAD_URL,
  UPDATE_CLASS_RESOURCE_URL,
  UPDATE_CHILD_SUPPORT_SCHEME,
  GET_LEARNING_GOALS_OPTIONS_LIST,
  GET_FEE_TIER_FOR_CHILD,
  EMERGENCY_CONTACT_INFO,
  ADD_EDIT_CONTACT_INFO,
  FIND_SUGGEST_PROGRAM_LIST,
  CREATE_OR_UPDATE_CLASS_ACTIVITY,
  GET_CHILD_HEALTH_INFO,
  UPDATE_CHILD_HEALTH_INFO,
  CHECK_BANK_ACCOUNT,
  WITHDRAW_CHILD_AT_PRE_ENROLMENT,
  SAVE_ENROLLED_CHILD_DOCUMENT,
  REMOVE_ENROLLED_CHILD_DOCUMENT_ITEM,
  REMOVE_ENROLLED_CHILD_DOCUMENT,
  UPDATE_MARKETING_CONSENT,
  UPDATE_AUDIO_CONSENT,
  GET_APPLICABLE_FEE_TIERS_FOR_CHILD,
  GET_CHILD_FEE_TIER_FOR_CHILD,
  GET_UPCOMING_FEE_TIERS_FOR_CHILD,
  UPDATE_UPCOMING_FEE_TIER,
  RETAIN_CHILD,
  CANCEL_RETAIN_CHILD,
  GET_MARKETING_CONSENT_BY_PARENT,
  GET_ALL_CLASS_FOR_PROMOTION_CHILD,
  CANCEL_PROMOTION,
  SYNC_CHILD_DATA,
  UPDATE_CLASS_ACTIVITY_STATUS,
} from '../models/classManagementModels';
import { ADD_OR_UPDATE_SECTION_RESOURCES } from '../models/classResources';
import FIND_ALL_CHILD_ACTIVITY_LOG from '../models/activityLogModel';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  GET_ALL_GUARDIANS,
  GET_ALL_SEARCH_GUARDIANS,
} from '../models/allGuardian';
import {
  ASSETSIN_COMPLETE,
  ASSETSIN_INPROGRESS,
  ASSETSIN_TODO,
  FEATURE_FLAGS,
} from '../../utils/constants';
import { msFetchClassActivities } from '../../components/views/ClassManagement/ClassActivity/ClassActivityHelper';
import {
  msSearchClassActivityTag,
  msUpdateClassActivityStatus,
} from '../../components/views/ClassManagement/ClassActivity/Create/CreateActivityHelper';

const { CLASS_ACTIVITY_MEDIA } =
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CLASS_ACTIVITY;

const dispatchImageUpload = (dispatch, data = {}, error = null) => {
  dispatch({
    type: SET_UPLOAD_IMAGES,
    key: 'uploadImages',
    value: {
      data,
      error,
    },
  });
};

export const fetchImageUploader =
  (assets, mainStatus = 'todo') =>
  dispatch => {
    dispatchImageUpload(dispatch);
    try {
      dispatchImageUpload(
        dispatch,
        {
          assets,
          mainStatus,
        },
        null
      );
      return true;
    } catch (ex) {
      dispatchImageUpload(dispatch, null, {
        error: ex,
      });
      return false;
    }
  };

export const changeCurrentAssetStatus =
  (assets, id, status = ASSETSIN_TODO) =>
  dispatch => {
    dispatchImageUpload(dispatch);
    try {
      let alteringAssetId = assets.findIndex(e => e.status === ASSETSIN_TODO);
      if (status === ASSETSIN_COMPLETE) {
        alteringAssetId = assets.findIndex(
          e => e.status === ASSETSIN_INPROGRESS
        );
      }
      assets[alteringAssetId].status = status;
      let mainStatus = ASSETSIN_INPROGRESS;
      if (
        !assets.find(e => e.status === ASSETSIN_TODO) &&
        !assets.find(e => e.status === ASSETSIN_INPROGRESS)
      ) {
        mainStatus = ASSETSIN_COMPLETE;
      }
      dispatchImageUpload(
        dispatch,
        {
          assets,
          mainStatus,
        },
        null
      );
      return true;
    } catch (ex) {
      dispatchImageUpload(dispatch, null, {
        error: ex,
      });
      return false;
    }
  };

const dispatchFeeTierForChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FEE_TIER_FOR_CHILD,
    key: 'feeTierForChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchApplicableFeeTierForChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_APPLICABLE_FEE_TIER_FOR_CHILD,
    key: 'applicableFeeTierForChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchChildFeeTierForChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_FEE_TIER_FOR_CHILD,
    key: 'childFeeTierForChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchUpcomingChildFeeTierForChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_UPCOMING_FEE_TIER_FOR_CHILD,
    key: 'upcomingFeeTierForChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchActivityLog = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACTIVITY_LOG,
    key: 'listAllLogs',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchPromotionFormESign = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FORM2_COUNT,
    key: 'form2SignedChildren',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFindAllProgram = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FIND_ALL_PROGRAMS,
    key: 'programsList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchWithdrawalFormESign = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FORM3_COUNT,
    key: 'form3SignedChildren',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchClassActivities = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_ACTIVITIES,
    key: 'classActivities',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchClassActivityTags = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_ACTIVITY_TAGS,
    key: 'classActivityTags',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchClassActivityStickers = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_ACTIVITY_STICKERS,
    key: 'classActivityStickers',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllGuardianDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_GUARDIAN,
    key: 'listAllGuardian',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRequiredDocuments = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_REQ_DOCS,
    key: 'listAllReqDocs',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchChildUpdate = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_UPDATED_CHILD_PARTICULAR,
    key: 'updateChildDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchDocumentByCategory = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_DOCUMENT_BY_CATEGORY,
    key: 'listallDocsByCategory',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClearUploadedDocuments =
  (data = {}) =>
  dispatch => {
    dispatch({
      type: CLEAR_UPLOAD_DOCS,
      key: 'listAllUploadedDocs',
      value: data,
    });
  };

const dispatchUploadedUrl = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_UPLOAD_DOC_URL,
    key: 'listUploadedUrl',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchClassDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CLASSES,
    key: 'listAllClasses',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClassUpdateDetails = modalData => async dispatch => {
  dispatch({
    type: UPDATE_CLASS_DETAILS_PAGE,
    key: 'classUpdateData',
    value: modalData,
  });
};

export const dispatchClearAllClasses =
  (data = {}) =>
  dispatch => {
    dispatch({
      type: CLEAR_ALL_CLASSES,
      key: 'listAllClasses',
      value: data,
    });
  };

const dispatchChildrenToAssign = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILDREN_ASSIGN_CLASS,
    key: 'assignChildrenToClass',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAllTeachers = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_TEACHERS,
    key: 'listAllTeachers',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchChildrenData = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILDREN_DATA,
    key: data.key,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAllChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CHILD,
    key: 'fetchAllChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClearFetchAllChild =
  (data = {}) =>
  async dispatch => {
    dispatch({
      type: CLEAR_FETCH_ALL_CHILD,
      key: 'fetchAllChild',
      value: data,
    });
  };

export const dispatchClearCurrentChild = () => dispatch => {
  dispatch({
    type: CLEAR_CURRENT_CHILD,
    key: 'currentchildDetails',
    value: { inProgress: false },
  });
};

const dispatchCurrentChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CURRENT_CHILDID,
    key: 'currentchildDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const emergencyContactInfoLoading = () => ({
  type: FETCH_EMERGENCY_CONTACT_INFO,
  key: 'emergencyContactDetails',
  value: {
    inProgress: true,
  },
});

const emergencyContactInfoFetchedSuccess = draftDetail => ({
  type: FETCH_EMERGENCY_CONTACT_INFO_SUCCESS,
  key: 'emergencyContactDetails',
  value: {
    inProgress: false,
    data: draftDetail,
  },
});

const emergencyContactInfoFetchedError = error => ({
  type: FETCH_EMERGENCY_CONTACT_INFO_ERROR,
  key: 'emergencyContactDetails',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const clearEmergencyContactInfo = () => dispatch => {
  dispatch({
    type: CLEAR_EMERGENCY_CONTACT_INFO,
    key: 'emergencyContactDetails',
    value: null,
  });
};

export const updateCurrentChild = childData => async dispatch => {
  dispatch({
    type: FETCH_CURRENT_CHILDID,
    key: 'currentchildDetails',
    value: {
      inProgress: false,
      data: childData,
      error: null,
    },
  });
};

const dispatchPastChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_PAST_CHILDREN,
    key: 'getPastChildrenData',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchPastChildSearch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_PAST_CHILDREN_SEARCH,
    key: 'pastChildSearchResults',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchSetAssignOrPromoteDetail = data => async dispatch => {
  dispatch({
    type: SET_ASSIGN_OR_PROMOTE_DETAIL,
    key: 'assignOrPromoteDetail',
    value: {
      inProgress: false,
      data,
      error: null,
    },
  });
};

export const dispatchClearAssignOrPromoteDetail =
  (data = {}) =>
  dispatch => {
    dispatch({
      type: CLEAR_ASSIGN_OR_PROMOTE_DETAIL,
      key: 'assignOrPromoteDetail',
      value: {
        inProgress: false,
        data,
        error: null,
      },
    });
  };

export const dispatchFetchClassDetailById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_DETAIL,
    key: 'classDetailById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClearClassDetail =
  (data = {}) =>
  dispatch => {
    dispatch({
      type: CLEAR_CLASS_DETAIL,
      key: 'classDetailById',
      value: {
        inProgress: false,
        data,
        error: null,
      },
    });
  };

const dispatchClassResources = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_RESOURCES,
    key: 'classResources',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLearningGoalsOptionsList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_LEARNING_GOALS_OPTIONS_LIST,
    key: 'learningGoalsList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllGuardianSearchDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_GUARDIAN_SEARCH,
    key: 'listAllGuardian',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchAllSearchGuardians = reqData => async dispatch => {
  dispatchAllGuardianSearchDetails(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_SEARCH_GUARDIANS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllGuardianSearchDetails(dispatch, false, data.data);
    } else {
      dispatchAllGuardianSearchDetails(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllGuardianSearchDetails(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchAllGuardians = reqData => async dispatch => {
  dispatchAllGuardianDetails(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_GUARDIANS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllGuardianDetails(dispatch, false, data.data);
    } else {
      dispatchAllGuardianDetails(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllGuardianDetails(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const updateEnquiryData = (ID, data) => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: UPDATE_ENQUIRY_DETAILS,
        variables: {
          ID,
          data,
        },
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return ex;
  }
};

export const updateChildNationalityParticular = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: UPDATE_NATIONALITY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return ex;
  }
};

export const updateChildParticulars =
  (reqData, previousData) => async dispatch => {
    dispatchChildUpdate(dispatch);
    const nationlityReqData = [];
    const programReqData = [];
    const enrolmentDateReqData = [];
    const childNameReqData = [];
    const childBCReqData = [];
    const kidFundingReqData = [];
    const childRaceReqData = [];
    const childGenderReqData = [];
    const resData = {};

    if (previousData) {
      Object.assign(resData, previousData);
    }
    reqData.forEach(api => {
      if (api.key === 'nationality') {
        delete api.key;
        nationlityReqData.push({
          IDChild: api.IDChild,
          nationality: api.nationality,
          effectiveFrom: api.effectiveFrom,
        });
        Object.assign(resData, {
          nationality: api.nationality,
          effectiveFrom: api.effectiveFrom,
        });
      }
      if (api.key === 'enrolmentDate') {
        delete api.key;
        enrolmentDateReqData.push({
          IDChild: api.IDChild,
          newEnrolmentDate: api.enrolmentDate,
          programID: api.programID,
          newProgramID: api.newProgramID,
          serviceLevelID: api.serviceLevelID,
          newServiceLevelID: api.newServiceLevelID,
          ...(api.bypassExceeding !== undefined
            ? {
                bypassExceeding: api.bypassExceeding,
              }
            : {}),
        });
        Object.assign(resData, {
          IDChild: api.IDChild,
          newEnrolmentDate: api.enrolmentDate,
        });
      }
      if (api.key === 'programType') {
        delete api.key;
        programReqData.push({
          IDChild: api.IDChild,
          IDProgram: api.IDProgram,
          effectiveFrom: api.effectiveFrom,
        });
        Object.assign(resData, {
          IDProgram: api.IDProgram,
          programTypeEffectiveFrom: api.effectiveFrom,
        });
      }

      if (api.key === 'childName') {
        delete api.key;
        childNameReqData.push({
          IDChild: api.IDChild,
          fullName: api.fullName,
        });
        Object.assign(resData, {
          fullName: api.fullName,
        });
      }

      if (api.key === 'childBirthCertificate') {
        delete api.key;
        const birthCertificate = api.birthCertificate
          ? api.birthCertificate.trim()
          : api.birthCertificate;
        childBCReqData.push({
          IDChild: api.IDChild,
          birthCertificate,
          isPrOrCitizen: isPrOrCitizen(api.nationality),
        });
        Object.assign(resData, {
          birthCertificate,
        });
      }

      if (api.key === 'kidFunding') {
        delete api.key;
        kidFundingReqData.push({
          childID: api.childID,
          codeID: api.codeID,
        });
        Object.assign(resData, {
          codeID: api.codeID,
        });
      }

      if (api.key === 'childRace') {
        delete api.key;
        childRaceReqData.push({
          IDChild: api.IDChild,
          IDRaceCode: api.IDRaceCode,
        });
        Object.assign(resData, {
          IDRaceCode: api.IDRaceCode,
        });
      }

      if (api.key === 'childGender') {
        delete api.key;
        childGenderReqData.push({
          IDChild: api.IDChild,
          gender: api.gender,
        });
        Object.assign(resData, {
          gender: api.gender,
        });
      }
    });

    const nationalityGql = `{
    ID
    nationality
  }`;

    const programTypeGql = '';
    const updateEnrolmentDateEnrolledChildGql = '';

    const childNameGql = `{
    ID
  }`;
    const childBCGql = `{
    ID
  }`;
    const kidFundingGql = `{ 
    ID
  }`;
    const childGenderGql = `{
    ID
  }`;
    const childRaceGql = `{
    ID
  }`;

    const params = [];
    if (nationlityReqData.length) {
      params.push({
        key: 'updateChildNationality',
        query: nationalityGql,
        variables: nationlityReqData,
      });
    }

    if (programReqData.length) {
      params.push({
        key: 'updateChildProgramType',
        query: programTypeGql,
        variables: programReqData,
      });
    }
    if (enrolmentDateReqData.length) {
      params.push({
        key: 'UpdateEnrolmentDateEnrolledChild',
        query: updateEnrolmentDateEnrolledChildGql,
        variables: enrolmentDateReqData,
      });
    }

    if (childNameReqData.length) {
      params.push({
        key: 'updateChildName',
        query: childNameGql,
        variables: childNameReqData,
      });
    }

    if (childBCReqData.length) {
      params.push({
        key: 'updateChildBC',
        query: childBCGql,
        variables: childBCReqData,
      });
    }

    if (kidFundingReqData.length) {
      params.push({
        key: 'updateKidStartFundingForChild',
        query: kidFundingGql,
        variables: kidFundingReqData,
      });
    }

    if (childGenderReqData.length) {
      params.push({
        key: 'updateChildGender',
        query: childGenderGql,
        variables: childGenderReqData,
      });
    }

    if (childRaceReqData.length) {
      params.push({
        key: 'updateChildRace',
        query: childRaceGql,
        variables: childRaceReqData,
      });
    }

    try {
      if (!params.length) {
        return {
          success: true,
          data: null,
        };
      }
      const result = await SkApolloRequest({
        params,
        type: 'bulkMutation',
      });
      dispatchChildUpdate(dispatch, false, resData);
      return result;
    } catch (ex) {
      return ex;
    }
  };

export const uploadChildDocsFile = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlGetDocumentUploadURLByIDDocumentTypeConfig,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const saveChildDocsFile = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlSaveDocument,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateChildImage = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlUpdateChildByFields,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateImage = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlGetClassPhotoUploadURL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getChildPhotoUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlGetChildPhotoUploadURL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateClassDetails = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlUpdateClassDetails,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const deleteClassAndTeachers = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlDeleteClassAndTeachers,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const newClassCreated = (newClass, teacherList) => async () => {
  const reqData = {
    newClass,
    teacherList,
  };
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlCreateNewClass,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchAllTeachers =
  (ftsInput, fkSchool, IDCentre, isGetSeniorTeacher) => async dispatch => {
    dispatchFetchAllTeachers(dispatch);
    try {
      const data = await SkApolloRequest({
        params: {
          query: gqlgetTeacherListOnInput,
          variables: {
            ftsInput,
            fkSchool,
            IDCentre,
            isGetSeniorTeacher,
          },
        },
        type: 'query',
      });
      if (data.success) {
        dispatchFetchAllTeachers(dispatch, false, data.data);
      } else {
        dispatchFetchAllTeachers(dispatch, false, null, data.error);
      }
    } catch (ex) {
      dispatchFetchAllTeachers(dispatch, false, null, { error: ex.message });
    }
  };

export const fetchAssignChildrenToClass = reqData => async dispatch => {
  dispatchChildrenToAssign(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILDREN_TO_ASSIGN_CLASS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchChildrenToAssign(dispatch, false, data.data);
    } else {
      dispatchChildrenToAssign(dispatch, false, null, data.error);
      return data.error;
    }
    return data;
  } catch (ex) {
    dispatchChildrenToAssign(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchPastChildrenData =
  (reqData, options = {}) =>
  async dispatch => {
    let dispatchFunc = dispatchPastChild;
    if (has(reqData, 'ftsInput') || has(reqData, 'IDChild')) {
      dispatchFunc = dispatchPastChildSearch;
    }
    const newReqData = Object.assign({}, reqData);
    if (has(reqData, 'ftsInput')) {
      newReqData.pagination = {
        page: 1,
        perPage: 10,
      };
    }
    dispatchFunc(dispatch);
    try {
      const data = await SkApolloRequest({
        params: {
          query: GET_PAST_CHILDREN_DATA,
          variables: newReqData,
          ...options,
        },
        type: 'query',
      });
      if (data.success) {
        const modifiedChildData = {
          getPastChildrenData: {
            data: null,
          },
          totalCount: get(data.data, 'getPastChildrenData.totalCount'),
        };

        if (get(data, 'data.getPastChildrenData.data.length')) {
          modifiedChildData.getPastChildrenData.data = get(
            data,
            'data.getPastChildrenData.data'
          ).map(childData => ({
            ...childData.child,
            to: childData.withdrawnDate,
            from: childData.enrolmentDate,
            centre: childData.centre,
          }));
        }
        dispatchFunc(dispatch, false, { data: modifiedChildData });
      } else {
        dispatchFunc(dispatch, false, null, data.error);
      }
    } catch (ex) {
      dispatchFunc(dispatch, false, null, { error: ex.message });
    }
  };

export const fetchAllChild = reqData => async dispatch => {
  dispatchFetchAllChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CHILD,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchAllChild(dispatch, false, data.data);
    } else {
      dispatchFetchAllChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchAllChild(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchChildrenData =
  (reqData, options = {}) =>
  async dispatch => {
    dispatchChildrenData(dispatch, true, { key: 'getChildrenData' }, null);
    try {
      const data = await SkApolloRequest({
        params: {
          query: GET_CHILDREN_DATA,
          variables: reqData,
          ...options,
        },
        type: 'query',
      });
      if (data.success) {
        dispatchChildrenData(dispatch, false, {
          data: data.data,
          key: 'getChildrenData',
        });
      } else {
        dispatchChildrenData(
          dispatch,
          false,
          { key: 'getChildrenData' },
          data.error
        );
      }
    } catch (ex) {
      dispatchChildrenData(
        dispatch,
        false,
        { key: 'getChildrenData' },
        { error: ex.message }
      );
    }
  };

export const fetchcurrentChildDetails =
  (IDChild, isDispatch = true) =>
  async (dispatch, getState) => {
    if (isDispatch) {
      dispatchCurrentChild(dispatch);
    }
    try {
      const data = await SkApolloRequest({
        params: {
          query: gqlchildByID,
          variables: {
            IDChild,
          },
        },
        type: 'query',
      });
      if (isDispatch) {
        if (data.success) {
          const currentState = getState();
          dispatchCurrentChild(dispatch, false, {
            data: {
              ...data.data,
              viewingUser: get(currentState, 'userDetails.data'),
            },
          });
        } else {
          dispatchCurrentChild(dispatch, false, null, data.error);
        }
      }
      return data;
    } catch (ex) {
      dispatchCurrentChild(dispatch, false, null, { error: ex.message });
    }
  };

export const getSelectedChild = IDChild => async dispatch => {
  dispatchCurrentChild(dispatch, true, { key: 'childByID' }, null);
  try {
    const data = await SkApolloRequest({
      params: {
        query: gqlchildByID,
        variables: {
          IDChild,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchCurrentChild(dispatch, false, {
        data: data.data,
        key: 'childByID',
      });
    } else {
      dispatchCurrentChild(dispatch, false, { key: 'childByID' }, data.error);
    }
  } catch (ex) {
    dispatchCurrentChild(
      dispatch,
      false,
      { key: 'childByID' },
      { error: ex.message }
    );
  }
};

export const assignChildrenToClass = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ASSIGN_CHILD_TO_CLASS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const createOrUpdateEmergencyContact = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_EDIT_CONTACT_INFO,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateMarketingConsent = (parentID, consent) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_MARKETING_CONSENT,
        variables: {
          IDParent: parentID,
          consent,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateAudioConsent = (childID, consent) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_AUDIO_CONSENT,
        variables: {
          IDChild: childID,
          consent,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const promoteChildrenAndAssignClass = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: PROMOTE_AND_ASSIGN_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const retainChild = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: RETAIN_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelRetainedChild = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_RETAIN_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveEnquiryDocument = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlApproveEnquiryDocument,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectEnquiryDocument = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlRejectEnquiryDocument,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const markEnquiryDocumentAsNotRequired = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: gqlMarkEnquiryDocumentAsNotRequired,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchRequiredDocs = reqData => async dispatch => {
  dispatchRequiredDocuments(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: gqlListRequiredDocuments,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchRequiredDocuments(dispatch, false, data.data);
    } else {
      dispatchRequiredDocuments(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchRequiredDocuments(dispatch, false, null, { error: ex.message });
  }
};

export const fetchEmergencyContactInfo = reqData => async dispatch => {
  dispatch(emergencyContactInfoLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: EMERGENCY_CONTACT_INFO,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(emergencyContactInfoFetchedSuccess(data.data));
    } else {
      dispatch(emergencyContactInfoFetchedError(data.error));
    }
  } catch (ex) {
    dispatch(emergencyContactInfoFetchedError(ex.ex.message));
  }
};

const fetchDocumentUploadedLoading = () => ({
  type: FETCH_UPLOAD_DOCS_REQUEST,
  value: {
    inProgress: true,
    data: [],
    error: null,
  },
});

const fetchDocumentUploadedSuccess = data => ({
  type: FETCH_UPLOAD_DOCS_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const fetchDocumentUploadedError = error => ({
  type: FETCH_UPLOAD_DOCS_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

export const fetchUploadedDocs = reqData => async dispatch => {
  dispatch(fetchDocumentUploadedLoading());
  try {
    const res = await SkApolloRequest({
      params: {
        query: gqlGetUploadedDocument,
        variables: reqData,
      },
      type: 'query',
    });
    if (res.success) {
      const data = get(res, 'data.listAllEnrolledChildDocuments.data');
      dispatch(fetchDocumentUploadedSuccess(data));
    } else {
      dispatch(fetchDocumentUploadedError(res.error));
    }
    return res;
  } catch (ex) {
    dispatch(fetchDocumentUploadedError(ex.message));
  }
};

const updateUploadedDocsByTypeLoading = () => ({
  type: UPDATE_DOCS_UPLOADED_BY_TYPE_REQUEST,
  value: {
    inProgress: true,
    error: null,
  },
});

const updateUploadedDocsByTypeSuccess = data => ({
  type: UPDATE_DOCS_UPLOADED_BY_TYPE_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const updateUploadedDocsByTypeError = error => ({
  type: UPDATE_DOCS_UPLOADED_BY_TYPE_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

export const updateUploadedDocsByType =
  ({ childID, documentTypeID }) =>
  async dispatch => {
    dispatch(updateUploadedDocsByTypeLoading());
    try {
      const res = await SkApolloRequest({
        params: {
          query: gqlGetUploadedDocument,
          variables: {
            childID,
            documentTypeIDs: [documentTypeID],
          },
        },
        type: 'query',
      });
      if (res.success) {
        const data = get(res, 'data.listAllEnrolledChildDocuments.data');
        dispatch(updateUploadedDocsByTypeSuccess(data));
      } else {
        dispatch(updateUploadedDocsByTypeError(res.error));
      }
    } catch (ex) {
      dispatch(updateUploadedDocsByTypeError(ex.message));
    }
  };

export const fetchDocumentByCategory = reqData => async dispatch => {
  dispatchDocumentByCategory(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: gqlgetDocumentTypeByDocumentCategory,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchDocumentByCategory(dispatch, false, data.data);
    } else {
      dispatchDocumentByCategory(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchDocumentByCategory(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchUploadedUrl = reqData => async dispatch => {
  dispatchUploadedUrl(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: gqlgetEnrolmentDocumentURL,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchUploadedUrl(dispatch, false, data.data);
    } else {
      dispatchUploadedUrl(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchUploadedUrl(dispatch, false, null, { error: ex.message });
  }
};

export const fetchAllActivityLogs = reqData => async dispatch => {
  dispatchActivityLog(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CHILD_ACTIVITY_LOG,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchActivityLog(dispatch, false, data.data);
    } else {
      dispatchActivityLog(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchActivityLog(dispatch, false, null, { error: ex.message });
  }
};

export const fetchClassManagementList = reqData => async dispatch => {
  dispatchClassDetails(dispatch);
  reqData.filter = omitBy(reqData.filter, filter => filter === '');
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CLASS_MANAGEMENT_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassDetails(dispatch, false, data.data);
    } else {
      dispatchClassDetails(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchClassDetails(dispatch, false, null, { error: ex.message });
  }
};

export const fetchClassDetailById = (fkSchool, classID) => async dispatch => {
  dispatchFetchClassDetailById(dispatch);
  const variables = {
    fkSchool,
    filter: {
      ID: classID,
    },
  };
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CLASS_DETAIL,
        variables,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchClassDetailById(dispatch, false, data.data);
    } else {
      dispatchFetchClassDetailById(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchClassDetailById(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchClassList = reqData => async dispatch => {
  dispatchClassDetails(dispatch);
  reqData.filter = omitBy(reqData.filter, filter => filter === '');
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CLASS_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassDetails(dispatch, false, data.data);
    } else {
      dispatchClassDetails(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchClassDetails(dispatch, false, null, { error: ex.message });
  }
};

export const getPromotionFormESignChildren = () => async dispatch => {
  dispatchPromotionFormESign(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: PENDING_PROMOTION_FORM,
        variables: {
          pendingStatus: 'PendingStaffSign',
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchPromotionFormESign(dispatch, false, data.data);
    } else {
      dispatchPromotionFormESign(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchPromotionFormESign(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getPendingWithdrawalESignChildren = () => async dispatch => {
  dispatchWithdrawalFormESign(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: PENDING_WITHDRAWAL_FORM,
        variables: {
          pendingStatus: 'PendingStaffSign',
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchWithdrawalFormESign(dispatch, false, data.data);
    } else {
      dispatchWithdrawalFormESign(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchWithdrawalFormESign(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const findAllPrograms = filter => async dispatch => {
  dispatchFindAllProgram(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_PROGRAM_LIST,
        variables: {
          filter,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFindAllProgram(dispatch, false, data.data);
    } else {
      dispatchFindAllProgram(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFindAllProgram(dispatch, false, null, { error: ex.message });
  }
};

export const suggestPrograms = (centreID, levelID) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_SUGGEST_PROGRAM_LIST,
        variables: {
          centreID,
          levelID,
        },
      },
      type: 'query',
    });
    return data;
  } catch (ex) {
    return { error: ex.message };
  }
};

const monoFetchClassActivities = async (_, reqDatas) => {
  const { reqData, dispatch } = reqDatas;
  dispatchClassActivities(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_CLASS_ACTIVITIES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassActivities(dispatch, false, data.data);
    } else {
      dispatchClassActivities(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchClassActivities(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchClassActivities = reqData => async dispatch => {
  dispatchClassActivities(dispatch);
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2B_CLASS_ACTIVITY_DISABLED,
    {
      dispatch,
      msHandler: msFetchClassActivities,
      monoHandler: monoFetchClassActivities,
      extraData: { reqData, dispatch, dispatchClassActivities },
    }
  );
  return data;
};

const monoUpdateClassActivityStatus = async (_, reqDatas) => {
  try {
    const { reqData } = reqDatas;
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_CLASS_ACTIVITY_STATUS,
        variables: get(reqData, 'variables'),
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

const msStatusUpdateClassActivity = async (_, reqDatas) => {
  try {
    const { dispatch, reqData } = reqDatas;
    const data = await msUpdateClassActivityStatus(
      dispatch,
      get(reqData, 'variables')
    );
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateClassActivityStatus = reqData => async dispatch => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2B_CLASS_ACTIVITY_DISABLED,
    {
      dispatch,
      msHandler: msStatusUpdateClassActivity,
      monoHandler: monoUpdateClassActivityStatus,
      extraData: { reqData, dispatch },
    }
  );
  return data;
};

export const msFetchClassActivityTags = async (_, dataParam) => {
  const { reqData, dispatch } = dataParam;
  dispatchClassActivityTags(dispatch);
  try {
    const data = await msSearchClassActivityTag(dispatch, reqData);
    dispatchClassActivityTags(dispatch, false, data);
  } catch (ex) {
    dispatchClassActivityTags(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const monoFetchClassActivityTags = async (_, dataParam) => {
  const { reqData, dispatch } = dataParam;
  dispatchClassActivityTags(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_CLASS_ACTIVITY_TAGS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassActivityTags(dispatch, false, data.data);
    } else {
      dispatchClassActivityTags(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchClassActivityTags(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchClassActivityTags = reqData => async dispatch => {
  dispatchClassActivities(dispatch);
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2B_CLASS_ACTIVITY_DISABLED,
    {
      dispatch,
      msHandler: msFetchClassActivityTags,
      monoHandler: monoFetchClassActivityTags,
      extraData: { reqData, dispatch },
    }
  );
  return data;
};

export const fetchClassActivityStickers = schoolID => async dispatch => {
  dispatchClassActivityStickers(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_STICKER,
        variables: {
          schoolID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassActivityStickers(dispatch, false, data.data);
    } else {
      dispatchClassActivityStickers(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchClassActivityStickers(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchClassResources = reqData => async dispatch => {
  dispatchClassResources(dispatch);
  try {
    const filter = {
      type: 'section',
      fkClass: reqData.fkClass,
      fkCentre: reqData.fkCentre,
    };
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CLASS_RESOURCES,
        variables: { filter },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassResources(dispatch, false, data.data);
    } else {
      dispatchClassResources(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchClassResources(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const addOrUpdateSectionResources = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_OR_UPDATE_SECTION_RESOURCES,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getResourceFileUploadUrl = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_RESOURCE_FILE_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateResourceUrl = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_CLASS_RESOURCE_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateSupportScheme = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_CHILD_SUPPORT_SCHEME,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateClassActivity = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_OR_UPDATE_CLASS_ACTIVITY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const bulkUploadActivityImages = imagesData => async () => {
  try {
    const bulkMutationReqData = imagesData.map(
      ({ IDClassActivity, file }, index) => ({
        queryKey: `imageUpload${index + 1}`,
        key: 'getClassActivityImageUploadURL',
        query: '{ Url, Key, Filename }',
        variables: [
          {
            IDClassActivity,
            file,
          },
        ],
      })
    );
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

const monoBulkClassActivityImagesUpload = async (_, reqBody) => {
  try {
    const { reqData } = reqBody;
    const imagesData = reqData;
    const bulkMutationReqData = imagesData.map(
      (
        { IDClassActivity, file, imageDto, ClassActivityMediaID = null },
        index
      ) => ({
        queryKey: `imageUpload${index + 1}`,
        key: 'createOrUpdateClassActivityMedia',
        query: `{
          UploadInfo {
            Filename
            Url
            Key
          }
          ClassActivityImage {
            ID
            caption
            statusProcess
            fkClassActivity
          }
        }`,
        variables: [
          {
            IDClassActivity,
            dto: {
              ClassActivityMediaID,
              file,
              classActivityImageInputDto: imageDto,
            },
          },
        ],
      })
    );
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

const msBulkClassActivityImagesUpload = async (_, imagesData) => {
  try {
    const { reqData = [] } = imagesData;
    const uploadList = [];
    reqData.forEach((item, idx) => {
      uploadList.push(
        classOpsService().post(CLASS_ACTIVITY_MEDIA, {
          class_activity_id: get(item, 'IDClassActivity'),
          class_activity_image: {
            caption: get(item, 'imageDto.Caption'),
            status: get(item, 'imageDto.Status'),
            status_process: get(item, 'imageDto.StatusProcess'),
            type: get(item, 'imageDto.Type'),
            index: get(item, 'imageDto.Index', idx),
          },
          file: {
            file_name: get(item, 'file.fileName'),
            file_size: get(item, 'file.fileSize'),
            mime_type: get(item, 'file.MIMEType'),
          },
          class_activity_image_id: get(item, 'ClassActivityMediaID'),
        })
      );
    });
    const response = (await Promise.all(uploadList)) || [];
    let responseData = {};
    response.forEach((item, index) => {
      const data = get(item, 'data');
      responseData = {
        ...responseData,
        [`imageUpload${index + 1}`]: {
          ClassActivityImage: {
            ID: get(data, 'class_activity_image.id'),
            fkClassActivity: get(
              data,
              'class_activity_image.fk_class_activity'
            ),
            url: get(data, 'class_activity_image.url'),
            caption: get(data, 'class_activity_image.caption'),
            statusProcess: get(
              data,
              'class_activity_image.status_process_string'
            ),
          },
          UploadInfo: {
            Filename: get(data, 'upload_info.file_name'),
            Key: get(data, 'upload_info.key'),
            Url: get(data, 'upload_info.url'),
          },
        },
      };
    });
    return {
      success: true,
      data: responseData,
    };
  } catch (ex) {
    return ex;
  }
};

export const bulkClassActivityImagesUpload = reqData => async dispatch => {
  try {
    dispatchClassActivities(dispatch);
    const data = await actionHandlerWithFeatureFlag(
      FEATURE_FLAGS.MS_PHASE_2B_CLASS_ACTIVITY_DISABLED,
      {
        dispatch,
        monoHandler: monoBulkClassActivityImagesUpload,
        msHandler: msBulkClassActivityImagesUpload,
        extraData: { reqData, dispatch },
      }
    );
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchLearningGoalsOptionsList = reqData => async dispatch => {
  dispatchLearningGoalsOptionsList(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        querying: GET_LEARNING_GOALS_OPTIONS_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchLearningGoalsOptionsList(dispatch, false, data.data);
    } else {
      dispatchLearningGoalsOptionsList(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchLearningGoalsOptionsList(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchFeeTierForChild = childID => async dispatch => {
  dispatchFeeTierForChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_FEE_TIER_FOR_CHILD,
        variables: { childID },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFeeTierForChild(dispatch, false, data.data);
    } else {
      dispatchFeeTierForChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFeeTierForChild(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

const fetchChildHealthLoading = () => ({
  type: FETCH_CHILD_HEALTH_INFO,
  key: 'childHealthInfo',
  value: {
    inProgress: true,
    error: null,
  },
});

const fetchChildHealthSuccess = data => ({
  type: FETCH_CHILD_HEALTH_INFO_SUCCESS,
  key: 'ChildHealthInfo',
  value: {
    inProgress: false,
    data,
  },
});

const fetchChildHealthError = error => ({
  type: FETCH_CHILD_HEALTH_INFO_ERROR,
  key: 'ChildHealthInfo',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const fetchChildHealthInfo = childId => async dispatch => {
  dispatch(fetchChildHealthLoading());
  try {
    const res = await SkApolloRequest({
      params: {
        query: GET_CHILD_HEALTH_INFO,
        variables: { childId },
      },
      type: 'query',
    });
    const data = get(res, 'data.getChildHealthInfoByChildID');
    dispatch(fetchChildHealthSuccess(data));
  } catch (ex) {
    dispatch(fetchChildHealthError(ex.message));
  }
};

const updateChildHealthLoading = () => ({
  type: UPDATE_CHILD_HEALTH_INFO_REQUEST,
  key: 'childHealthInfo',
  value: {
    inProgress: true,
  },
});

const updateChildHealthSuccess = data => ({
  type: UPDATE_CHILD_HEALTH_INFO_SUCCESS,
  key: 'ChildHealthInfo',
  value: {
    inProgress: false,
    data,
  },
});

const updateChildHealthError = error => ({
  type: UPDATE_CHILD_HEALTH_INFO_ERROR,
  key: 'ChildHealthInfo',
  value: {
    inProgress: false,
    error,
  },
});

export const updateChildHealthInfo =
  (childId, healthInfo) => async dispatch => {
    dispatch(updateChildHealthLoading());
    try {
      const res = await SkApolloRequest({
        params: {
          mutation: UPDATE_CHILD_HEALTH_INFO,
          variables: { childId, healthInfo },
        },
        type: 'mutation',
      });
      if (res.success) {
        const data = get(res, 'data.updateChildHealthInfoByChildID');
        dispatch(updateChildHealthSuccess(data));
      }
      dispatch(updateChildHealthError(get(res, 'error.0.message')));
    } catch (ex) {
      dispatch(updateChildHealthError(ex.message));
    }
  };

export const checkBankAccountForPreEnrolmentWithdrawal =
  IDChild => async () => {
    try {
      const data = await SkApolloRequest({
        params: {
          mutation: CHECK_BANK_ACCOUNT,
          variables: { IDChild },
        },
        type: 'mutation',
      });
      return data;
    } catch (ex) {
      return ex;
    }
  };

export const withdrawChildAtPreEnrolment = requestData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: WITHDRAW_CHILD_AT_PRE_ENROLMENT,
        variables: requestData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const saveEnrolledChilDocument = params => async () => {
  try {
    const res = await SkApolloRequest({
      params: {
        mutation: SAVE_ENROLLED_CHILD_DOCUMENT,
        variables: {
          childID: params.childID,
          parentID: params.parentID,
          documentTypeConfigID: params.documentTypeConfigID,
          uploadedDocuments: params.uploadedDocuments,
        },
      },
      type: 'mutation',
    });
    return res;
  } catch (err) {
    return err;
  }
};

const removeEnrolledChidDocLoading = () => ({
  type: REMOVE_ENROLLED_CHILD_DOC_REQUEST,
  value: {
    inProgress: true,
    error: null,
  },
});

const removeEnrolledChidDocSuccess = data => ({
  type: REMOVE_ENROLLED_CHILD_DOC_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const removeEnrolledChidDocError = error => ({
  type: REMOVE_ENROLLED_CHILD_DOC_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

export const removeEnrolledChildDocument =
  (childID, parentID, documentID, reason) => async dispatch => {
    dispatch(removeEnrolledChidDocLoading());
    try {
      const res = await SkApolloRequest({
        params: {
          mutation: REMOVE_ENROLLED_CHILD_DOCUMENT,
          variables: {
            childID,
            parentID,
            documentID,
            reason,
          },
        },
        type: 'mutation',
      });
      if (res.success) {
        dispatch(removeEnrolledChidDocSuccess({ documentID }));
      }
      dispatch(removeEnrolledChidDocError(get(res, 'error.0.message')));
    } catch (ex) {
      dispatch(removeEnrolledChidDocError(ex.message));
    }
  };

const removeEnrolledChidDocTypeLoading = () => ({
  type: REMOVE_ENROLLED_CHILD_DOC_TYPE_REQUEST,
  value: {
    inProgress: true,
    error: null,
  },
});

const removeEnrolledChidDocTypeSuccess = data => ({
  type: REMOVE_ENROLLED_CHILD_DOC_TYPE_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const removeEnrolledChidDocTypeError = error => ({
  type: REMOVE_ENROLLED_CHILD_DOC_TYPE_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

export const removeEnrolledChildDocumentItem =
  (childID, parentID, documentTypeID, reason) => async dispatch => {
    dispatch(removeEnrolledChidDocTypeLoading());
    try {
      const res = await SkApolloRequest({
        params: {
          mutation: REMOVE_ENROLLED_CHILD_DOCUMENT_ITEM,
          variables: {
            childID,
            parentID,
            documentTypeID,
            reason,
          },
        },
        type: 'mutation',
      });
      if (res.success) {
        dispatch(removeEnrolledChidDocTypeSuccess({ documentTypeID }));
      }
      dispatch(removeEnrolledChidDocTypeError(get(res, 'error.0.message')));
      return res;
    } catch (ex) {
      dispatch(removeEnrolledChidDocTypeError(ex.message));
    }
  };

export const getApplicableFeeTiersForChild = reqData => async dispatch => {
  if (!reqData.centreID) {
    return;
  }
  dispatchApplicableFeeTierForChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_APPLICABLE_FEE_TIERS_FOR_CHILD,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchApplicableFeeTierForChild(dispatch, false, data.data);
    } else {
      dispatchApplicableFeeTierForChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchApplicableFeeTierForChild(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const getChildFeeTierForChild = reqData => async dispatch => {
  dispatchChildFeeTierForChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILD_FEE_TIER_FOR_CHILD,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchChildFeeTierForChild(dispatch, false, data.data);
    } else {
      dispatchChildFeeTierForChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchChildFeeTierForChild(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const getUpcomingChildFeeTierForChild = reqData => async dispatch => {
  dispatchUpcomingChildFeeTierForChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_UPCOMING_FEE_TIERS_FOR_CHILD,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchUpcomingChildFeeTierForChild(dispatch, false, data.data);
    } else {
      dispatchUpcomingChildFeeTierForChild(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchUpcomingChildFeeTierForChild(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const updateUpcomingChildFeeTier = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_UPCOMING_FEE_TIER,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

const getMarketingConsentByParentRequest = () => ({
  type: GET_MARKETING_CONSENT_BY_PARENT_REQUEST,
  value: {
    inProgress: true,
    error: null,
  },
});

const getMarketingConsentByParentSuccess = data => ({
  type: GET_MARKETING_CONSENT_BY_PARENT_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const getMarketingConsentByParentError = error => ({
  type: GET_MARKETING_CONSENT_BY_PARENT_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

export const getMarketingConsentByParent =
  (schoolID, parentID) => async dispatch => {
    dispatch(getMarketingConsentByParentRequest());
    try {
      const res = await SkApolloRequest({
        params: {
          query: GET_MARKETING_CONSENT_BY_PARENT,
          variables: {
            schoolID,
            parentID,
          },
        },
        type: 'query',
      });
      if (res.success) {
        const data = get(
          res,
          'data.getMarketingConsentBusinessEntityByParentAndSchool'
        );
        dispatch(getMarketingConsentByParentSuccess(data));
      } else {
        dispatch(getMarketingConsentByParentError(get(res, 'error.0.message')));
      }
    } catch (ex) {
      dispatch(getMarketingConsentByParentError(ex.message));
    }
  };
export const getAllClassInfoByChild = async ({
  childID,
  effectiveDate,
  isHidden,
}) => {
  try {
    const { data } = await SkApolloRequest({
      params: {
        query: GET_ALL_CLASS_FOR_PROMOTION_CHILD,
        variables: {
          childID,
          effectiveDate,
          isHidden,
        },
      },
      type: 'query',
    });

    return get(data, 'getAllClassInfoForChild.data') || [];
  } catch {
    return [];
  }
};

export const cancelPromotion = async (childID, bypassExceeding) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_PROMOTION,
        variables: {
          IDChild: childID,
          bypassExceeding,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const syncChildData = variables => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SYNC_CHILD_DATA,
        variables,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

// refer
export const getAllClassByChildList = async ({
  childIDs,
  effectiveDate,
  isHidden,
}) => {
  try {
    const bulkQueryReqData = childIDs.map(childID => ({
      queryKey: `getAllClassInfo${childID}`,
      key: `getAllClassInfoForChild`,
      query: `{
        totalCount
        data {
          ID
          label
          description
        }
      }`,
      variables: [
        {
          childID,
          effectiveDate,
          isHidden,
        },
      ],
    }));
    const result = await SkApolloRequest({
      params: bulkQueryReqData,
      type: 'bulkQuery',
    });
    return result;
  } catch {
    return null;
  }
};

export const getClassForPromotionChildList = async ({
  childList,
  fkSchool,
  onlyCurrentClass,
}) => {
  try {
    const bulkQueryReqData = childList.map(child => ({
      queryKey: `getAllClassInfo${child.childID}`,
      key: `getAllClassInfo`,
      query: `{
        totalCount
        data {
          ID
          label
          description
          isHidden
        }
      }`,
      variables: [
        {
          filter: {
            fkCentre: child.centre,
            fkLevel: child.level,
          },
          fkSchool,
          onlyCurrentClass,
        },
      ],
    }));

    const result = await SkApolloRequest({
      params: bulkQueryReqData,
      type: 'bulkQuery',
    });
    return result;
  } catch {
    return [];
  }
};

export default fetchClassList;
