import { get, uniq, compact, isEmpty } from 'lodash';
import DatadogHandler from 'utils/datadog';
import {
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
  classOpsService,
  userService,
  schoolService,
} from '../../../../utils/msEndpointConstants';
import { DEFAULT_ERROR } from '../../../../utils/constants';
import { retrieveSortForMS } from '../../../../utils';

const {
  REJECT_MS_CLASS_ACTIVITY,
  FIND_CLASS_ACTIVITY,
  GET_CLASS_ACTIVITY_TAGS,
  GET_CLASS_ACTIVITY_LOGS,
  GET_CLASS_ACTIVITY_IMAGES,
  GET_CLASS_ACTIVITY_PARENT_INTERACTIONS,
  GET_CLASS_ACTIVITY_LEARNING_GOALS,
  GET_CLASS_ACTIVITY_CLASSES,
  GET_CLASS_ACTIVITY_CHILDRENS,
  GET_CLASS_ACTIVITY_DEVELOPMENT_LEARNING_AREAS,
  GET_CLASS_ACTIVITY_LESSON_PLANS,
} = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CLASS_ACTIVITY;

const { GET_BY_IDS } = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.USER;

const { GET_LEARNING_GOALS_BY_IDS, GET_LEARNING_GOALS_BY_DOMAIN_IDS } =
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CLASS;

const { GET_LEVEL_BY_IDS } = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.SCHOOL;

const { GET_DOMAINS_BY_DOMAIN_IDS } =
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.PORTFOLIO;

/**
 * Microservice func to save Semester Checklist Evaluation
 * @param {*} _ dispatch (optional)
 * @param {*} reqBody request body
 * @returns a promise
 */
export const msRejectClassActivity = async (_, reqBody = {}) => {
  try {
    const classActivityId = get(reqBody, 'classActivityID');
    const body = {
      remark: reqBody?.remarks,
    };
    const res = await classOpsService().post(
      REJECT_MS_CLASS_ACTIVITY(classActivityId),
      body
    );
    if (get(res, 'data.success')) {
      return {
        data: {
          rejectClassActivity: true,
        },
      };
    }
    return {
      errors: [get(res, 'data.error') || get(res, 'message', DEFAULT_ERROR)],
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return {
      errors: [
        get(e, 'response.data.error') || get(e, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const transformClassActivityLogs = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const logs = {};

  Object.keys(data).forEach(key => {
    const log = get(data[key], 'data') || [];
    logs[key] = {
      classActivityLogs:
        log.length > 0
          ? {
              data: log.map(lg => ({
                ID: get(lg, 'id'),
                action: get(lg, 'action_string'),
                updatedAt: get(lg, 'updated_at'),
                createdAt: get(lg, 'created_at'),
                remarks: get(lg, 'remarks'),
                fkUser: get(lg, 'fk_user'),
              })),
            }
          : {},
    };
  });

  return logs;
};

export const transformClassActivityLogsAndUsers = (users = {}, logs = {}) => {
  const userData = get(users, 'data.data') || {};

  Object.keys(logs).forEach(key => {
    const log = get(logs[key], 'classActivityLogs.data') || [];
    if (log.length > 0) {
      const logData = log.map(lg => {
        const fkUser = get(lg, 'fkUser');
        const user = userData.find(usr => get(usr, 'id') === fkUser);
        const userUpdated = user
          ? {
              ID: get(user, 'id'),
              firstname: get(user, 'firstname'),
              lastname: get(user, 'lastname'),
              imageKey: get(user, 'image_key'),
              imageKeyPresignedURL: get(user, 'image_key_presigned_url'),
            }
          : null;
        return { ...lg, user: userUpdated };
      });
      logs[key] = {
        classActivityLogs: {
          data: logData,
        },
      };
    }
  });

  return logs;
};

export const transformClassActivityImages = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const images = {};
  Object.keys(data).forEach(key => {
    const image = get(data[key], 'data') || [];
    images[key] = {
      classActivityImages: {
        ...(image.length > 0 && {
          data: image.map(img => ({
            ID: get(img, 'id'),
            uRL: get(img, 'url'),
            urlPath: get(img, 'url'),
            urlPresigned: get(img, 'url_s3_presigned_url'),
            urlEncodedPresigned: get(img, 'url_encoded_presigned_url'),
            status: get(img, 'status_string'),
            caption: get(img, 'caption'),
            type: get(img, 'type'),
            format: get(img, 'format'),
            formatWithPresignedURL: get(img, 'format_with_presigned_url'),
            statusProcess: get(img, 'status_process_string'),
          })),
        }),
      },
    };
  });

  return images;
};

export const transformParentInteractions = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const parentInteractions = {};

  Object.keys(data).forEach(key => {
    const parentInteraction = get(data[key], 'data') || [];
    parentInteractions[key] = {
      classActivityParentInteractions: {
        ...(parentInteraction.length > 0 && {
          data: parentInteraction.map(pi => ({
            action: get(pi, 'action_string'),
          })),
        }),
      },
    };
  });

  return parentInteractions;
};

export const transformLearningGoals = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const learningGoals = {};

  Object.keys(data).forEach(key => {
    const learningGoal = get(data[key], 'data') || [];
    learningGoals[key] = {
      classActivityLearningGoals: {
        ...(learningGoal.length > 0 && {
          data: learningGoal.map(lg => ({
            ID: get(lg, 'id'),
            fkLearningGoal: get(lg, 'fk_learning_goal'),
          })),
        }),
      },
    };
  });

  return learningGoals;
};

export const transformClassActivityLearningGoals = (
  lGByClassActivity = {},
  lGByIds = {}
) => {
  const data = get(lGByClassActivity, 'data.data') || {};
  Object.keys(lGByIds).forEach(dt => {
    const learningGoals =
      get(lGByIds[dt], 'classActivityLearningGoals.data') || [];
    if (learningGoals.length > 0) {
      const lgData = learningGoals.map(lG => {
        const fkLearningGoal = get(lG, 'fkLearningGoal');
        const learningGoal = data.find(lg => get(lg, 'id') === fkLearningGoal);
        let learningGoalUpdated = null;
        if (learningGoal) {
          learningGoalUpdated = {
            ID: get(learningGoal, 'id'),
            title: get(learningGoal, 'title'),
            fkLearningGoal: get(learningGoal, 'fk_learning_goal'),
            fkDomain: get(learningGoal, 'fk_domain'),
          };
        }
        lG = { learningGoal: learningGoalUpdated };
        return lG;
      });
      lGByIds[dt] = {
        classActivityLearningGoals: {
          data: lgData,
        },
      };
    }
  });
  return lGByIds;
};

export const transformClasses = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const classes = {};

  Object.keys(data).forEach(key => {
    const classData = get(data[key], 'data') || [];
    classes[key] = {
      class: {
        ...(classData.length > 0 && {
          data: classData.map(cl => ({
            ID: get(cl, 'id'),
            label: get(cl, 'label'),
            fkLevel: get(cl, 'fk_level'),
          })),
        }),
      },
    };
  });

  return classes;
};

export const transformClassesAndLevels = (levels = {}, classes = {}) => {
  const data = get(levels, 'data.data') || {};

  Object.keys(classes).forEach(key => {
    const classData = get(classes[key], 'class.data') || [];
    if (classData.length > 0) {
      const clsData = classData.map(cls => {
        const fkLevel = get(cls, 'fkLevel');
        const level = data.find(lvl => get(lvl, 'id') === fkLevel);
        const levelUpdated = level
          ? {
              ID: get(level, 'id'),
              label: get(level, 'label'),
              code: get(level, 'code'),
            }
          : null;
        cls.level = levelUpdated;
        return cls;
      });
      classes[key] = {
        class: {
          data: clsData,
        },
      };
    }
  });

  return classes;
};

export const transformChildData = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const childLists = {};
  Object.keys(data).forEach(key => {
    const childData = get(data[key], 'data') || [];
    childLists[key] = {
      child: {
        ...(childData.length > 0 && {
          data: childData.map(child => ({
            ID: get(child, 'id'),
            firstname: get(child, 'firstname', ''),
            lastname: get(child, 'lastname', ''),
            imageKey: get(child, 'image_key'),
            imageKeyPresignedURL: get(child, 'image_key_presigned_url'),
          })),
        }),
      },
    };
  });

  return childLists;
};

export const transformDevelopmentalAreas = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const developmentalAreas = {};
  Object.keys(data).forEach(key => {
    const developmentalArea = get(data[key], 'data') || [];
    developmentalAreas[key] = {
      classActivityDevelopmentalAndLearningAreas: {
        ...(developmentalArea.length > 0 && {
          data: developmentalArea.map(cl => ({
            ID: get(cl, 'id'),
            fkSubDomain: get(cl, 'fk_sub_domain'),
            fkDomain: get(cl, 'fk_domain'),
            learningGoals: {
              title: get(cl, 'learning_goal_title'),
            },
          })),
        }),
      },
    };
  });

  return developmentalAreas;
};

export const transformLessonPlans = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const lessonPlans = {};

  Object.keys(data).forEach(key => {
    const lessonPlan = get(data[key], 'data') || [];
    lessonPlans[key] = {
      ...(lessonPlan.length > 0 && {
        lessonPlan: lessonPlan.map(lP => ({
          ID: get(lP, 'id'),
          label: get(lP, 'label'),
          title: get(lP, 'title'),
          termCode: get(lP, 'term_code_string'),
        })),
      }),
    };
  });

  return lessonPlans;
};

export const transformClassActivityData = (res = {}) => {
  const data = get(res, 'data');
  return {
    totalCount: get(data, 'totalCount'),
    data: (get(data, 'data') || []).map(dt => ({
      ID: get(dt, 'id'),
      status: get(dt, 'status_string'),
      title: get(dt, 'title'),
      description: get(dt, 'description'),
      link: get(dt, 'link'),
      allowComment: get(dt, 'allow_comment'),
      remarks: get(dt, 'remarks'),
      createdAt: get(dt, 'created_at'),
      updatedAt: get(dt, 'updated_at'),
      publishedAt: get(dt, 'published_at'),
      involvement: get(dt, 'involvement'),
      wellbeing: get(dt, 'wellbeing'),
      displayDate: get(dt, 'display_date'),
      type: get(dt, 'type_string'),
      likesCount: get(dt, 'likes_count'),
      pinned: get(dt, 'pinned'),
      qqlClassSpaceID: get(dt, 'qql_class_space_id'),
      interpretation: get(dt, 'interpretation'),
      ...(get(dt, 'child') && { child: get(dt, 'child') }),
    })),
  };
};

export const transformfindClassActivities = ({
  classActivities = {},
  classActivityImages = {},
  classActivityImageMigrateds = {},
  classActivityLogs = {},
  classActivityParentInteractions = {},
  classes = {},
  child = {},
  classActivityLearningGoals = {},
  classActivityTagRelations = {},
  lessonPlan = {},
  developmentalAndLearningAreas = {},
  resp,
}) => {
  const classActivity = get(classActivities, 'data') || [];
  const classActivityData = [];
  classActivity.forEach(cA => {
    const classActivityId = get(cA, 'ID');
    const data = {
      ...cA,
      ...classActivityImages[classActivityId],
      ...classActivityImageMigrateds[classActivityId],
      ...classActivityLogs[classActivityId],
      ...classActivityParentInteractions[classActivityId],
      ...classes[classActivityId],
      ...(!isEmpty(child) && child[classActivityId]),
      ...classActivityLearningGoals[classActivityId],
      ...classActivityTagRelations[classActivityId],
      ...lessonPlan[classActivityId],
      ...developmentalAndLearningAreas[classActivityId],
    };
    classActivityData.push(data);
  });
  return {
    totalCount: get(resp, 'totalCount', 0),
    data: classActivityData,
  };
};

export const transformTags = (res = {}) => {
  const data = get(res, 'data.data') || {};
  const tagData = {};
  Object.keys(data).forEach(key => {
    const tags = get(data[key], 'data') || [];
    tagData[key] = {
      classActivityTagRelations: {
        ...(tags.length > 0 && {
          data: tags.map(lP => ({
            classActivityTag: {
              ID: get(lP, 'fk_activity_tag'),
              name: get(lP, 'activity_tag_name'),
            },
          })),
        }),
      },
    };
  });
  return tagData;
};

export const transformDevelopmentalAndLearningAreas = (
  learningGoals = {},
  domains = {},
  learningAreas = {}
) => {
  const learningGoalData = get(learningGoals, 'data.data') || [];
  const domainData = get(domains, 'data.data') || [];
  Object.keys(learningAreas).forEach(dt => {
    const learningArea =
      get(
        learningAreas[dt],
        'classActivityDevelopmentalAndLearningAreas.data'
      ) || [];
    if (learningArea.length > 0) {
      const lAData = learningArea.map(lA => {
        const fkDomain = get(lA, 'fkDomain');
        const learningGoal = learningGoalData.find(
          lG => get(lG, 'id') === fkDomain
        );
        let learningGoalUpdated = null;
        if (learningGoal) {
          learningGoalUpdated = {
            ID: get(learningGoal, 'id'),
            title: get(learningGoal, 'title'),
          };
        }
        lA.learningGoal = learningGoalUpdated;
        const domain = domainData.find(dom => get(dom, 'id') === fkDomain);
        let domainUpdated = null;
        if (domain) {
          domainUpdated = {
            ID: get(domain, 'id'),
            title: get(domain, 'title'),
          };
        }
        lA.domain = domainUpdated;
        return lA;
      });
      learningAreas[dt] = {
        classActivityDevelopmentalAndLearningAreas: {
          data: lAData,
        },
      };
    }
  });
  return learningAreas;
};

export const msFetchClassActivities = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchClassActivities = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    let reqDatas = reqData;
    const signal = get(reqBody, 'controller.signal', null);
    const _classOpsService = classOpsService();
    const _userService = userService();
    const _schoolService = schoolService();
    let queryParams = {};
    if (get(reqBody, 'filter.ID')) {
      reqDatas = reqBody;
    } else {
      queryParams = {
        page: get(reqDatas, 'pagination.page'),
        perPage: get(reqDatas, 'pagination.perPage'),
        ...retrieveSortForMS(get(reqDatas, 'pagination.sort')),
      };
    }
    const body = {
      child_ids: get(reqDatas, 'filterDto.IDChildren'),
      class_ids: get(reqDatas, 'filterDto.IDClasses'),
      tag_ids: get(reqDatas, 'filterDto.IDTags'),
      users_ids: get(reqDatas, 'filterDto.IDUsers'),
      status: get(reqDatas, 'filter.status'),
      types: get(reqDatas, 'filterDto.Types'),
      ...(get(reqDatas, 'filter.ID') && {
        class_activity_id: parseInt(get(reqDatas, 'filter.ID')),
      }),
      ...(get(reqDatas, 'filterDto.From') && {
        from: get(reqDatas, 'filterDto.From'),
      }),
      ...(get(reqDatas, 'filterDto.To') && {
        to: get(reqDatas, 'filterDto.To'),
      }),
    };
    const fullApiUrl = `${FIND_CLASS_ACTIVITY}?${new URLSearchParams(
      queryParams
    ).toString()}`;
    const res = await classOpsService().post(fullApiUrl, body, { signal });
    const resp = transformClassActivityData(res);
    const classActivityIds = (get(res, 'data.data') || []).map(dt =>
      get(dt, 'id')
    );
    if (get(resp, 'data.length', 0) === 0) {
      const emptyData = {
        data: {
          findClassActivities: {
            data: [],
          },
          totalCount: 0,
        },
      };
      dispatchClassActivities(dispatch, false, emptyData);
      return emptyData;
    }
    const [
      classActivityTags,
      classActivityLogs,
      classActivityImages,
      classActivityParenInteractions,
      classActivityLearningGoals,
      classActivityClasses,
      classActivityChildrens,
      classActivityDevelopmentLearningAreas,
      classActivityLessonPlans,
    ] = await Promise.all([
      _classOpsService
        .post(GET_CLASS_ACTIVITY_TAGS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_LOGS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(
          ...GET_CLASS_ACTIVITY_IMAGES(classActivityIds, {
            sort: 'index',
          }),
          {}
        )
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_PARENT_INTERACTIONS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_LEARNING_GOALS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_CLASSES, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_CHILDRENS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_DEVELOPMENT_LEARNING_AREAS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
      _classOpsService
        .post(GET_CLASS_ACTIVITY_LESSON_PLANS, {
          class_activity_ids: classActivityIds,
        })
        .catch(() => ({})),
    ]);
    const tagsUpdated = transformTags(classActivityTags);
    const logsUpdated = transformClassActivityLogs(classActivityLogs);
    const userIds = [];
    Object.keys(logsUpdated).forEach(key => {
      const activityLog = logsUpdated[key];
      const logData = get(activityLog, 'classActivityLogs.data') || [];
      if (logData.length > 0) {
        userIds.push(...logData.map(lg => get(lg, 'fkUser')));
      }
    });
    const fkUsers = compact(uniq(userIds));
    let classActivityLogsData = {};
    if (fkUsers.length > 0) {
      const users = await _userService.post(...GET_BY_IDS(fkUsers));
      classActivityLogsData = transformClassActivityLogsAndUsers(
        users,
        logsUpdated
      );
    }
    const imagesUpdated = transformClassActivityImages(classActivityImages);
    const parentInteractionsUpdated = transformParentInteractions(
      classActivityParenInteractions
    );
    const lGUpdated = transformLearningGoals(classActivityLearningGoals);
    const learningGoalIds = [];
    Object.keys(lGUpdated).forEach(key => {
      const learningGoalData = lGUpdated[key];
      const lGData =
        get(learningGoalData, 'classActivityLearningGoals.data') || [];
      if (lGData.length > 0) {
        learningGoalIds.push(...lGData.map(lG => get(lG, 'fkLearningGoal')));
      }
    });
    const fkLearningGoal = compact(uniq(learningGoalIds));
    let learningGoalsUpdated = {};
    if (fkLearningGoal.length > 0) {
      const learningGoalByIds = await _classOpsService.post(
        ...GET_LEARNING_GOALS_BY_IDS(fkLearningGoal)
      );
      learningGoalsUpdated = transformClassActivityLearningGoals(
        learningGoalByIds,
        lGUpdated
      );
    }
    const classesUpdated = transformClasses(classActivityClasses);
    const levelIds = [];
    Object.keys(classesUpdated).forEach(key => {
      const classData = classesUpdated[key];
      const clsData = get(classData, 'class.data') || [];
      if (clsData.length > 0) {
        levelIds.push(...clsData.map(cl => get(cl, 'fkLevel')));
      }
    });
    const fkLevels = compact(uniq(levelIds));
    let classLevels = {};
    if (fkLevels.length > 0) {
      const levels = await _schoolService.post(...GET_LEVEL_BY_IDS(fkLevels));
      classLevels = transformClassesAndLevels(levels, classesUpdated);
    }
    const childLists = transformChildData(classActivityChildrens);
    const learingAreasUpdated = transformDevelopmentalAreas(
      classActivityDevelopmentLearningAreas
    );
    let domainIds = [];
    Object.keys(learingAreasUpdated).forEach(key => {
      const lAData = learingAreasUpdated[key];
      const lADetails =
        get(lAData, 'classActivityDevelopmentalAndLearningAreas.data') || [];
      if (lADetails.length > 0) {
        domainIds = [...domainIds, ...lADetails.map(la => get(la, 'fkDomain'))];
      }
    });
    const fkDomain = compact(uniq(domainIds));
    let developmentalAndLearningAreas = {};
    let learningGoalByDomain = {};
    let domainByIds = {};
    if (fkDomain?.length > 0) {
      learningGoalByDomain = await _classOpsService.post(
        ...GET_LEARNING_GOALS_BY_DOMAIN_IDS(fkDomain)
      );

      domainByIds = await _classOpsService.post(
        ...GET_DOMAINS_BY_DOMAIN_IDS(fkDomain)
      );
    }
    developmentalAndLearningAreas = transformDevelopmentalAndLearningAreas(
      learningGoalByDomain,
      domainByIds,
      learingAreasUpdated
    );

    const lessonPlansUpdated = transformLessonPlans(classActivityLessonPlans);

    const results = transformfindClassActivities({
      classActivities: resp,
      classActivityImages: imagesUpdated,
      classActivityLogs: classActivityLogsData,
      classActivityParentInteractions: parentInteractionsUpdated,
      classes: classLevels,
      child: childLists,
      classActivityLearningGoals: learningGoalsUpdated,
      classActivityTagRelations: tagsUpdated,
      lessonPlan: lessonPlansUpdated,
      developmentalAndLearningAreas,
      resp,
    });
    if (get(reqDatas, 'filter.ID')) {
      return {
        findClassActivities: results,
      };
    }
    dispatchClassActivities(dispatch, false, results);
    return {
      data: {
        findClassActivities: results,
      },
    };
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    const emptyData = {
      data: {
        findClassActivities: {
          data: [],
        },
        totalCount: 0,
      },
    };
    dispatchClassActivities(dispatch, false, emptyData);
    return emptyData;
  }
};

export default {
  msRejectClassActivity,
  msFetchClassActivities,
  transformClassActivityData,
  transformTags,
  transformClassActivityLogs,
  transformClassActivityLogsAndUsers,
  transformClassActivityImages,
  transformParentInteractions,
  transformLessonPlans,
  transformfindClassActivities,
  transformLearningGoals,
  transformDevelopmentalAndLearningAreas,
  transformClassActivityLearningGoals,
  transformDevelopmentalAreas,
  transformChildData,
  transformClasses,
  transformClassesAndLevels,
};
