import gql from 'graphql-tag';
import {
  INVOICE,
  BILLABLE_ITEM,
  UPLOAD_INFO,
  RECEIPT,
  VOID_SUBSIDY_FRAGMENT,
} from './commonFragments';

/* ==== Query ==== */
export const FIND_ALL_PURCHASE_ITEM = gql`
  query findAllPurchaseItem(
    $IDCentre: Int!
    $filter: BillableItemFilter
    $pagination: Pagination
  ) {
    findAllPurchaseItem(
      IDCentre: $IDCentre
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...billableItem
      }
    }
  }
  ${BILLABLE_ITEM}
`;

export const GET_CHILD_CURRENT_BALANCE = gql`
  query getChildCurrentBalance($IDChild: Int!) {
    getChildCurrentBalance(IDChild: $IDChild) {
      CDABalance
      nonCDABalance
    }
  }
`;

export const LIST_REGISTRATION_CHILD_BALANCE_SHEET = gql`
  query getRegistrationChildBalanceSheet(
    $registrationChildID: Int!
    $IDCentre: Int!
  ) {
    getRegistrationChildBalanceSheet(
      registrationChildId: $registrationChildID
      centreId: $IDCentre
    ) {
      cdaCredit
      nonCdaCredit
      cdaOutstanding
      nonCdaOutstanding
    }
  }
`;

export const LIST_REGISTRATION_TOTAL_ADVANCE_PAYMENTS = gql`
  query getRegistrationChildTotalAdvancePaymentAmount(
    $registrationChildID: Int!
    $registrationID: Int
  ) {
    getRegistrationChildTotalAdvancePaymentAmount(
      registrationChildID: $registrationChildID
      registrationID: $registrationID
    ) {
      CDABalance
      nonCDABalance
    }
  }
`;

export const GET_CHILD_BALANCE_SHEET = gql`
  query getChildBalanceSheet($childID: Int!, $centreID: Int!) {
    getChildBalanceSheet(childId: $childID, centreId: $centreID) {
      cdaCredit
      nonCdaCredit
      cdaOutstanding
      nonCdaOutstanding
    }
  }
`;

export const FIND_CHILD_REFUNDS = gql`
  query getRefundChildBalance(
    $childID: Int!
    $searchTerm: String
    $filter: RefundChildBalanceFilter
    $pagination: Pagination
  ) {
    getRefundChildBalance(
      childID: $childID
      ftsInput: $searchTerm
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        refundAt
        amount
        requestedAt
        refundMode
        cancellationReason
        status
        remark
        documentNo
        type
      }
    }
  }
`;

export const FIND_ALL_REG_CHILD_REFUNDS = gql`
  query getRegistrationRefundChildBalance(
    $registrationChildID: Int!
    $registrationID: Int
    $ftsInput: String
    $filter: RefundChildBalanceFilter
    $pagination: Pagination
  ) {
    getRegistrationRefundChildBalance(
      registrationChildID: $registrationChildID
      registrationID: $registrationID
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        refundAt
        amount
        requestedAt
        refundMode
        status
        remark
        type
        cancellationReason
      }
    }
  }
`;

export const FIND_ALL_CHILD_REFUNDS = gql`
  query getAllRefundChildBalance(
    $childID: Int
    $registrationChildID: Int
    $ftsInput: String
    $filter: RefundChildBalanceFilter
    $pagination: Pagination
  ) {
    getAllRefundChildBalance(
      childID: $childID
      registrationChildID: $registrationChildID
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        refundAt
        amount
        requestedAt
        refundMode
        status
        remark
        type
        cancellationReason
      }
    }
  }
`;

export const FIND_ALL_CHILD_REFUNDS_FOR_ECDA = gql`
  query getAllRefundChildBalanceForECDA(
    $childID: Int
    $ecdaRegistrationChildID: Int
    $ftsInput: String
    $filter: RefundChildBalanceFilter
    $pagination: Pagination
  ) {
    getAllRefundChildBalanceForECDA(
      childID: $childID
      ecdaRegistrationChildID: $ecdaRegistrationChildID
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        refundAt
        amount
        requestedAt
        refundMode
        status
        remark
        type
        cancellationReason
      }
    }
  }
`;

export const CREATE_ADHOC_CHILD_REFUND = gql`
  mutation createAdhocChildRefund(
    $childID: Int!
    $centreID: Int!
    $cdaAmount: Float!
    $nonCDAAmount: Float!
    $remark: String!
  ) {
    createAdhocChildRefund(
      childID: $childID
      centreID: $centreID
      cdaAmount: $cdaAmount
      nonCDAAmount: $nonCDAAmount
      remark: $remark
    ) {
      ID
    }
  }
`;

export const GET_LIST_OF_ENROLLED_CENTRES = gql`
  query getChildEnrolledCentreList($childID: Int!, $includeUpComing: Boolean!) {
    getChildEnrolledCentreList(
      childID: $childID
      includeUpComing: $includeUpComing
    ) {
      data {
        ID
        label
      }
    }
  }
`;
export const INVOICES_BY_FK_CHILD = gql`
  query invoicesByFkChild(
    $IDChild: Int!
    $filter: InvoiceFilter
    $pagination: Pagination
  ) {
    invoicesByFkChild(
      IDChild: $IDChild
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...invoiceData
      }
    }
  }
  ${INVOICE}
`;

export const BANK_ACCOUNTS_INFO_BY_CHILD_ID = gql`
  query bankAccountInfoByIDChild($IDChild: Int!) {
    bankAccountInfoByIDChild(IDChild: $IDChild) {
      BankAccount {
        bankAccountStateHistories {
          data {
            updatedAt
          }
        }
        ID
        childName
        childBirthCertificate
        child {
          firstname
          lastname
          birthCertificate
        }
        payerAccountNumber
        payerAccountName
        bankBicCode {
          bankName
          ID
        }
        source
        fkBankBicCode
        status
        remarks
        billReferenceNumber
        isCda
        isSiblingCda
        siblingName
        siblingBirthCertificate
        effectiveFrom
        updatedAt
        createdAt
        documentTypes {
          data {
            ID
            label
            documentTypeConfig {
              ID
            }
            documentTags {
              data {
                document {
                  filename
                  uRL
                  preSignedUrl
                }
              }
            }
          }
        }
      }
      GiroApplication {
        BankAccountNumber
        IsCDA
        NameOfAccountHolders
        MobilePhoneNumber
        MobilePhoneCountryCode
        ApplicationDate
      }
    }
  }
`;

export const FIND_ALL_BANK_BIC_CODE = gql`
  query findAllBankBicCode(
    $bbcFilter: BankBicCodeFilter
    $pagination: Pagination
  ) {
    findAllBankBicCode(bbcFilter: $bbcFilter, pagination: $pagination) {
      data {
        ID
        bankCode
        bankName
        bicCode
        displayOrder
        isCdaSupported
      }
    }
  }
`;

export const SEARCH_FINANCE = gql`
  query searchFinance(
    $ftsInput: String!
    $refundOnly: Boolean!
    $IDChild: Int
    $invoiceFilter: InvoiceFilter
    $receiptFilter: ReceiptFilter
    $cdnFilter: CreditDebitNoteFilter
    $refundCbalFilter: RefundChildBalanceFilter
  ) {
    searchFinance(
      ftsInput: $ftsInput
      refundOnly: $refundOnly
      IDChild: $IDChild
      invoiceFilter: $invoiceFilter
      receiptFilter: $receiptFilter
      cdnFilter: $cdnFilter
      refundCbalFilter: $refundCbalFilter
    ) {
      data {
        ID
        type
        label
        score
        linkedInvoiceID
      }
    }
  }
`;

export const SEARCH_INVOICE = gql`
  query searchInvoice($ftsInput: String!, $invoiceFilter: InvoiceFilter) {
    searchInvoice(ftsInput: $ftsInput, invoiceFilter: $invoiceFilter) {
      data {
        ID
        invoiceNo
        label
      }
    }
  }
`;

export const FIND_ALL_INVOICE = gql`
  query findAllInvoice($filter: InvoiceFilter, $pagination: Pagination) {
    findAllInvoice(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        fkChild
        fkRegistrationChild
        invoiceNo
        totalTaxAmount
        totalAmount
        outstandingAmount
        grandTotal
        label
        remark
        status
        invoiceType
        invoiceDate
        pdfUrl
        preSignedUrl
        createdAt
        type
        invoiceItems {
          data {
            ID
            fkInvoice
            totalAmount
            displayTotalAmount
            quantity
            taxAmount
            unitPrice
            forMonth
            billableItem {
              ID
              label
              unitPrice
              taxable
              type
              cdaDeductible
              displayOrder
            }
          }
        }
        receipts(pagination: { sort: ["-createdAt"] }) {
          data {
            ID
            receiptNo
            amountReceived
            creditAmountUsed
            createdAt
            paymentType
            pdfUrl
            preSignedUrl
            cancelationReason
            status
            type
          }
        }
        creditNotes(pagination: { sort: ["-createdAt"] }) {
          data {
            ID
            creditDebitNoteNo
            creditDebitNoteDate
            createdAt
            remarks
            totalAmount
            pdfUrl
            preSignedUrl
          }
        }
        debitNotes(pagination: { sort: ["-createdAt"] }) {
          data {
            ID
            creditDebitNoteNo
            creditDebitNoteDate
            createdAt
            remarks
            totalAmount
            pdfUrl
            preSignedUrl
          }
        }
        invoiceVoidNotes(pagination: { sort: ["-createdAt"] }) {
          data {
            voidNoteNo
            voidRemark
            voidAt
            invoice {
              ID
              grandTotal
            }
          }
        }
        child {
          firstname
          lastname
        }
        registrationChild {
          firstname
          lastname
          registrationChildMappings(
            pagination: { page: 1, perPage: 1, sort: ["-createdAt"] }
          ) {
            data {
              fkRegistration
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CREDIT_DEBIT_NOTES = gql`
  query findAllCreditDebitNotes(
    $writeOff: Boolean!
    $linkedInvoiceIds: [Int!]
    $filter: CreditDebitNoteFilter
    $pagination: Pagination
  ) {
    findAllCreditDebitNotes(
      writeOff: $writeOff
      linkedInvoiceIds: $linkedInvoiceIds
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        fkRegistrationChild
        totalAmount
        pdfUrl
        preSignedUrl
        createdAt
        reasonCode
        remarks
        creditDebitNoteNo
        noteType
        type
        creditDebitNoteItems {
          data {
            amount
            adjustedAmount
            ID
            fkInvoice
            fkCreditDebitNote
            invoice {
              invoiceNo
            }
            creditDebitNote {
              totalAmount
              creditDebitNoteNo
            }
          }
        }
        child {
          firstname
          lastname
        }
        registrationChild {
          firstname
          lastname
          registrationChildMappings(
            pagination: { page: 1, perPage: 1, sort: ["-createdAt"] }
          ) {
            data {
              fkRegistration
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CONSOLIDATED_REFUND = gql`
  query findAllConsolidatedRefund(
    $invoiceFilter: InvoiceFilter
    $refundCBalFilter: RefundChildBalanceFilter
    $IDCentre: Int
    $from: Datetime
    $to: Datetime
    $docType: String
    $pagination: Pagination
  ) {
    findAllConsolidatedRefund(
      invoiceFilter: $invoiceFilter
      refundCBalFilter: $refundCBalFilter
      IDCentre: $IDCentre
      from: $from
      to: $to
      docType: $docType
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        date
        refundCompletionDate
        label
        IDChild
        IDRegistrationChild
        IDRegistration
        childFirstname
        childLastname
        associatedItems
        amount
        type
        baseType
        status
      }
    }
  }
`;

export const FIND_ALL_UPLOADED_SUBSIDY_FILES = gql`
  query findAllUploadedSubsidyFiles($pagination: Pagination) {
    findAllUploadedSubsidyFiles(pagination: $pagination) {
      totalCount
      data {
        ID
        label
        filename
        uRL
        status
        log
        user {
          ID
          firstname
          lastname
        }
        createdAt
        presignedUrl
      }
    }
  }
`;

export const FIND_TAX_RATE_AND_AMOUNT = gql`
  query getTaxRateAndAmount($dto: InvoicePreviewDto!) {
    getTaxRateAndAmount(dto: $dto) {
      Items {
        TaxRate
        TaxAmount
        TotalAmount
        BillableItemId
        Quantity
        Period
        UnitPrice
        Label
        Type
      }
      TotalAmount
    }
  }
`;

export const FIND_ALL_DOWNLOADED_GIRO_FILES = gql`
  query findAllDownloadedGiroFiles(
    $filter: FinanceDocumentFilter
    $pagination: Pagination
  ) {
    findAllDownloadedGiroFiles(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        label
        uRL
        status
        giroStatus
        log
        docType
        presignedUrl
        user {
          ID
          firstname
          lastname
        }
        createdAt
        uploadedAt
        fkFinanceDocument
        financeDocuments(pagination: { sort: "-createdAt" }) {
          data {
            ID
            label
            uRL
            status
            log
            docType
            presignedUrl
            errorLog
            createdAt
            uploadedAt
            user {
              ID
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`;

export const GET_ADVANCE_PAYMENT_RECEIPTS = gql`
  query getAdvancePaymentReceipts(
    $childID: Int!
    $filter: ReceiptFilter
    $pagination: Pagination
  ) {
    getAdvancePaymentReceipts(
      childID: $childID
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...receipt
      }
    }
  }
  ${RECEIPT}
`;

export const GET_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION = gql`
  query getAdvancePaymentReceiptsForRegistration(
    $registrationChildID: Int!
    $registrationID: Int
    $filter: ReceiptFilter
    $pagination: Pagination
  ) {
    getAdvancePaymentReceiptsForRegistration(
      registrationChildID: $registrationChildID
      registrationID: $registrationID
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...receipt
      }
    }
  }
  ${RECEIPT}
`;

export const GET_ADVANCE_PAYMENT_RECEIPTS_FOR_ECDA_REGISTRATION = gql`
  query getAdvancePaymentReceiptsForEcdaRegistration(
    $ecdaRegistrationChildID: Int!
    $ecdaRegistrationID: Int
    $filter: ReceiptFilter
    $pagination: Pagination
  ) {
    getAdvancePaymentReceiptsForEcdaRegistration(
      ecdaRegistrationChildID: $ecdaRegistrationChildID
      ecdaRegistrationID: $ecdaRegistrationID
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...receipt
      }
    }
  }
  ${RECEIPT}
`;

/* ==== Mutation ==== */
export const CREATE_OFFLINE_INVOICE = gql`
  mutation createOfflineInvoice(
    $IDCentre: Int!
    $IDChild: Int!
    $items: [InvoiceItemCreateDTO!]!
    $remark: String
  ) {
    createOfflineInvoice(
      IDCentre: $IDCentre
      IDChild: $IDChild
      items: $items
      remark: $remark
    ) {
      ID
      label
      totalAmount
    }
  }
`;

export const CREATE_RECEIPT_FOR_OFFLINE_PAYMENT = gql`
  mutation createReceiptForOfflinePayment(
    $IDInvoice: [Int!]!
    $referenceNo: String!
    $paymentType: ReceiptPaymentType!
    $amount: Float
    $isSiblingCda: Boolean
  ) {
    createReceiptForOfflinePayment(
      IDInvoice: $IDInvoice
      referenceNo: $referenceNo
      paymentType: $paymentType
      amount: $amount
      isSiblingCda: $isSiblingCda
    ) {
      ID
      amount
      amountReceived
    }
  }
`;

export const GET_ID_DOCUMENT_TYPE_CONFIG_UPLOAD_URL = gql`
  mutation getDocumentUploadURLByIDDocumentTypeConfig(
    $IDDocumentTypeConfig: Int!
    $IDRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    getDocumentUploadURLByIDDocumentTypeConfig(
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const GET_ID_DOCUMENT_TYPE_CONFIG_GIRO_URL = gql`
  mutation GetGiroFormUploadURL(
    $IDBankAccount: Int!
    $IDDocumentTypeConfig: Int
    $IDRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    GetGiroFormUploadURL(
      IDBankAccount: $IDBankAccount
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const GET_ID_DOCUMENT_TYPE_CONFIG_BC_URL = gql`
  mutation getSiblingBirthCertificateUploadURL(
    $IDBankAccount: Int!
    $IDDocumentTypeConfig: Int
    $IDRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    getSiblingBirthCertificateUploadURL(
      IDBankAccount: $IDBankAccount
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const GET_ID_DOCUMENT_TYPE_CONFIG_ECDA_BC_URL = gql`
  mutation getEcdaSiblingBirthCertificateUploadURL(
    $IDBankAccount: Int!
    $IDDocumentTypeConfig: Int
    $IDEcdaRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    getEcdaSiblingBirthCertificateUploadURL(
      IDBankAccount: $IDBankAccount
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDEcdaRegChild: $IDEcdaRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const GET_CDA_ACCOUNT_STATEMENT_URL = gql`
  mutation getCdaAccountStatementUploadURL(
    $IDBankAccount: Int!
    $IDDocumentTypeConfig: Int
    $IDRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    getCdaAccountStatementUploadURL(
      IDBankAccount: $IDBankAccount
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const GET_ECDA_CDA_ACCOUNT_STATEMENT_URL = gql`
  mutation getEcdaCdaAccountStatementUploadURL(
    $IDBankAccount: Int!
    $IDDocumentTypeConfig: Int
    $IDEcdaRegChild: Int
    $IDChild: Int
    $IDEnquiry: Int
    $file: Upload!
  ) {
    getEcdaCdaAccountStatementUploadURL(
      IDBankAccount: $IDBankAccount
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDEcdaRegChild: $IDEcdaRegChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const GET_ID_DOCUMENT_TYPE_CONFIG_TEMP_BC_URL = gql`
  mutation getSiblingBirthCertificateTempUploadURL(
    $IDRegChild: Int
    $IDEcdaRegChild: Int
    $IDChild: Int
    $file: Upload!
  ) {
    getSiblingBirthCertificateTempUploadURL(
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDEcdaRegChild: $IDEcdaRegChild
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const SAVE_DOCUMENT = gql`
  mutation saveDocument(
    $uploadedDocuments: [DocumentUpload!]!
    $IDChild: Int!
    $IDEnquiry: Int
  ) {
    saveDocument(
      uploadedDocuments: $uploadedDocuments
      IDChild: $IDChild
      IDEnquiry: $IDEnquiry
    ) {
      ID
    }
  }
`;

export const CREATE_ADHOC_CREDIT_NOTE = gql`
  mutation createAdHocCreditNote($IDInvoice: Int!, $remark: String!) {
    createAdHocCreditNote(IDInvoice: $IDInvoice, remark: $remark)
  }
`;

export const SAVE_GIRO_APPLICATION = gql`
  mutation saveGiroApplication(
    $IDChild: Int!
    $IDSchool: Int!
    $giroApplication: GiroApplication!
    $isSiblingCda: Boolean
  ) {
    saveGiroApplication(
      IDChild: $IDChild
      IDSchool: $IDSchool
      giroApplication: $giroApplication
      isSiblingCda: $isSiblingCda
    ) {
      ID
      documentTypes {
        data {
          ID
          label
          documentTypeConfig {
            ID
          }
        }
      }
    }
  }
`;

export const SAVE_REG_GIRO_APPLICATION = gql`
  mutation saveRegGiroApplication(
    $IDRegChild: Int!
    $IDSchool: Int!
    $giroApplication: GiroApplication!
    $isSiblingCda: Boolean
  ) {
    saveRegGiroApplication(
      IDRegChild: $IDRegChild
      IDSchool: $IDSchool
      giroApplication: $giroApplication
      isSiblingCda: $isSiblingCda
    ) {
      ID
      documentTypes {
        data {
          ID
          label
          documentTypeConfig {
            ID
          }
        }
      }
    }
  }
`;

export const SAVE_CANCEL_REG_GIRO_APPLICATION = gql`
  mutation saveCancelRegistrationGiroAccounts(
    $IDRegChild: Int!
    $IDSchool: Int!
    $giroApplication: [GiroApplication!]!
  ) {
    saveCancelRegistrationGiroAccounts(
      IDRegChild: $IDRegChild
      IDSchool: $IDSchool
      giroApplication: $giroApplication
    ) {
      data {
        isCda
        documentTypes {
          data {
            ID
            label
            documentTypeConfig {
              ID
            }
          }
        }
      }
    }
  }
`;

export const GET_FINANCE_DOCUMENT_UPLOAD_URL = gql`
  mutation getFinanceDocumentUploadURL(
    $IDSchool: Int!
    $docType: FinanceDocumentDocType!
    $file: Upload!
  ) {
    getFinanceDocumentUploadURL(
      IDSchool: $IDSchool
      docType: $docType
      file: $file
    ) {
      ...uploadInfo
    }
  }
  ${UPLOAD_INFO}
`;

export const UPLOAD_SUBISDY_FILE = gql`
  mutation uploadSubsidyFile(
    $IDSchool: Int!
    $url: String!
    $filename: String!
  ) {
    uploadSubsidyFile(IDSchool: $IDSchool, url: $url, filename: $filename) {
      ID
    }
  }
`;

export const UPLOAD_GIRO_FILE = gql`
  mutation uploadGiroFile(
    $IDSchool: Int!
    $url: String!
    $filename: String!
    $docType: FinanceDocumentDocType!
    $IDRequestFinanceDocument: Int
  ) {
    uploadGiroFile(
      IDSchool: $IDSchool
      url: $url
      filename: $filename
      docType: $docType
      IDRequestFinanceDocument: $IDRequestFinanceDocument
    ) {
      ID
    }
  }
`;

export const UPDATE_GIRO_STATUS_WITH_REASON_MODEL = gql`
  mutation updateBankAccountStatus(
    $schoolID: Int!
    $bankAccountID: Int!
    $status: String!
    $remarks: String!
  ) {
    updateBankAccountStatus(
      schoolID: $schoolID
      bankAccountID: $bankAccountID
      status: $status
      remarks: $remarks
    ) {
      ID
      payerAccountNumber
      payerAccountName
      bankBicCode {
        ID
        bankName
      }
      source
      status
    }
  }
`;

export const GENERATE_GIRO_REFUND_ARCHIVE = gql`
  mutation generateGiroRefundArchive($IDSchool: Int!) {
    generateGiroRefundArchive(IDSchool: $IDSchool)
  }
`;

export const GENERATE_GIRO_DEDUCTION_ARCHIVE = gql`
  mutation generateGiroDeductionArchive($IDSchool: Int!) {
    generateGiroDeductionArchive(IDSchool: $IDSchool)
  }
`;

export const EXPORT_GIRO_APPLICATION_PDF = gql`
  mutation exportGiroApplicationPdf($bankAccountID: Int!, $centreId: Int!) {
    exportGiroApplicationPdf(IDBankAccount: $bankAccountID, centreId: $centreId)
  }
`;

export const PAYMENT_TYPES = gql`
  query getOfflinePaymentModes($schoolID: Int!) {
    getOfflinePaymentModes(schoolID: $schoolID) {
      totalCount
      data {
        label
        value
      }
    }
  }
`;

export const GET_BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS = gql`
  query getBankAccountStatusChangeReasonsByStatus(
    $schoolID: Int!
    $currentStatus: String!
    $selectedStatus: String!
  ) {
    getBankAccountStatusChangeReasonsByStatus(
      schoolID: $schoolID
      currentStatus: $currentStatus
      selectedStatus: $selectedStatus
    ) {
      totalCount
      data {
        ID
        description
        label
      }
    }
  }
`;

export const GET_ALL_CUSTOM_SUBSIDY_TYPE = gql`
  query findAllCustomSubsidies(
    $childID: Int!
    $centreID: Int
    $billableItemFilter: BillableItemFilter
  ) {
    findAllCustomSubsidies(
      childID: $childID
      centreID: $centreID
      billableItemFilter: $billableItemFilter
    ) {
      totalCount
      data {
        ID
        fkCentre
        label
        subsidyType
      }
    }
  }
`;

export const GET_ACTIVE_SUBSIDIES = gql`
  query findAllChildActiveSubsidies(
    $childID: Int!
    $centreID: Int!
    $pagination: Pagination
  ) {
    findAllChildActiveSubsidies(
      childID: $childID
      centreID: $centreID
      pagination: $pagination
    ) {
      ...subsidyData
    }
  }
  ${VOID_SUBSIDY_FRAGMENT}
`;

export const GET_HISTORY_SUBSIDIES = gql`
  query findAllChildHistorySubsidiesForBillingAdjustment(
    $childID: Int!
    $centreID: Int
    $pagination: Pagination
  ) {
    findAllChildHistorySubsidiesForBillingAdjustment(
      childID: $childID
      centreID: $centreID
      pagination: $pagination
    ) {
      totalCount
      data {
        invoiceID
        invoiceNo
        invoiceDate
        billableItemID
        billableItemLabel
        invoiceItemID
        finalTotalAmount
        forMonth
        status
        addBy
        listCreditDebitNote {
          data {
            createdAt
            creditDebitNoteNo
            creditDebitNoteDate
            remarks
            userByGeneratedByFkStaff {
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`;

export const GET_SUBSIDIES = gql`
  query findAllChildHistorySubsidies(
    $childID: Int!
    $centreID: Int
    $pagination: Pagination
  ) {
    findAllChildHistorySubsidies(
      childID: $childID
      centreID: $centreID
      pagination: $pagination
    ) {
      ...subsidyData
    }
  }
  ${VOID_SUBSIDY_FRAGMENT}
`;

export const EDIT_HISTORY_SUBSIDY = gql`
  mutation adjustMonthlyFeeInvoiceByBillableItemID(
    $invoiceID: Int!
    $invoiceItemID: Int
    $childID: Int!
    $billableItemID: Int!
    $remark: String!
    $amount: Float!
    $forMonth: Datetime!
  ) {
    adjustMonthlyFeeInvoiceByBillableItemID(
      invoiceID: $invoiceID
      invoiceItemID: $invoiceItemID
      childID: $childID
      billableItemID: $billableItemID
      remark: $remark
      amount: $amount
      forMonth: $forMonth
    )
  }
`;

export const ADD_CUSTOM_SUBSIDY_TO_CHILD = gql`
  mutation addCustomSubsidyToChild($dto: CustomSubsidyCreationDTO!) {
    addCustomSubsidyToChild(dto: $dto) {
      ID
    }
  }
`;

export const VOID_CUSTOM_SUBSIDY = gql`
  mutation voidCustomSubsidy($subsidyArrangementID: Int!, $remarks: String!) {
    voidCustomSubsidy(
      subsidyArrangementID: $subsidyArrangementID
      remarks: $remarks
    ) {
      ID
    }
  }
`;

export const APPROVE_CUSTOM_SUBSIDY = gql`
  mutation approveCustomSubsidy($customSubsidyID: Int!) {
    approveCustomSubsidy(customSubsidyID: $customSubsidyID)
  }
`;

export const REJECT_CUSTOM_SUBSIDY = gql`
  mutation rejectCustomSubsidy($customSubsidyID: Int!, $remark: String!) {
    rejectCustomSubsidy(customSubsidyID: $customSubsidyID, remark: $remark)
  }
`;

export const CANCEL_CUSTOM_SUBSIDY = gql`
  mutation cancelCustomSubsidy($customSubsidyID: Int!, $remark: String!) {
    cancelCustomSubsidy(customSubsidyID: $customSubsidyID, remark: $remark)
  }
`;

export const GET_SUBSIDY_DEBIT_AMOUNT = gql`
  query estimateVoidCustomSubsidyDebitNoteAmount($subsidyArrangementID: Int!) {
    estimateVoidCustomSubsidyDebitNoteAmount(
      subsidyArrangementID: $subsidyArrangementID
    )
  }
`;

export const CREATE_ADVANCE_PAYMENT = gql`
  mutation createAdvancePayment(
    $centreID: Int
    $childID: Int!
    $amount: Float!
    $referenceNo: String!
    $paymentType: ReceiptPaymentType!
    $remarks: String!
    $isSiblingCda: Boolean
  ) {
    createAdvancePaymentReceipt(
      centreID: $centreID
      childID: $childID
      amount: $amount
      referenceNo: $referenceNo
      paymentType: $paymentType
      remarks: $remarks
      isSiblingCda: $isSiblingCda
    ) {
      ...receipt
    }
  }
  ${RECEIPT}
`;

export const CREATE_ADVANCE_PAYMENT_FOR_REGISTRATION = gql`
  mutation createAdvancePaymentReceiptForRegistration(
    $registrationID: Int!
    $registrationChildID: Int!
    $amount: Float!
    $referenceNo: String!
    $remarks: String
    $paymentType: ReceiptPaymentType!
    $isSiblingCda: Boolean
  ) {
    createAdvancePaymentReceiptForRegistration(
      registrationID: $registrationID
      registrationChildID: $registrationChildID
      amount: $amount
      referenceNo: $referenceNo
      paymentType: $paymentType
      remarks: $remarks
      isSiblingCda: $isSiblingCda
    ) {
      ...receipt
    }
  }
  ${RECEIPT}
`;

export const CREATE_ECDA_ADVANCE_PAYMENT_FOR_REGISTRATION = gql`
  mutation createAdvancePaymentReceiptForEcdaRegistration(
    $registrationID: Int!
    $registrationChildID: Int!
    $amount: Float!
    $referenceNo: String!
    $remarks: String
    $paymentType: ReceiptPaymentType!
    $isSiblingCda: Boolean
  ) {
    createAdvancePaymentReceiptForEcdaRegistration(
      ecdaRegistrationID: $registrationID
      ecdaRegistrationChildID: $registrationChildID
      amount: $amount
      referenceNo: $referenceNo
      paymentType: $paymentType
      remarks: $remarks
      isSiblingCda: $isSiblingCda
    ) {
      ...receipt
    }
  }
  ${RECEIPT}
`;

export const GET_ALL_ABSENT_CHILD_VOID_SUBSIDY = gql`
  query findAllAbsentForVoidingSubsidyByChild($childID: Int!, $centreID: Int) {
    findAllAbsentForVoidingSubsidyByChild(
      childID: $childID
      centreID: $centreID
    ) {
      totalCount
      data {
        childID
        centreID
        month
      }
    }
  }
`;

export const REMOVE_CUSTOM_SUBSIDY_BY_CHILD = gql`
  mutation removeAbsentSubsidyByChild(
    $centreID: Int!
    $childID: Int!
    $month: Datetime!
  ) {
    removeAbsentSubsidyByChild(
      centreID: $centreID
      childID: $childID
      month: $month
    )
  }
`;

export const EXEMPT_CUSTOM_SUBSIDY_BY_CHILD = gql`
  mutation exemptAbsentSubsidyByChild(
    $centreID: Int!
    $childID: Int!
    $month: Datetime!
    $remarks: String!
  ) {
    exemptAbsentSubsidyByChild(
      centreID: $centreID
      childID: $childID
      month: $month
      remarks: $remarks
    )
  }
`;

export const CANCEL_RECEIPT = gql`
  mutation cancelReceipt($receiptID: Int!, $remark: String!) {
    cancelReceipt(receiptID: $receiptID, remark: $remark)
  }
`;

export const UNLINK_RECEIPT = gql`
  mutation unlinkReceipt($receiptID: Int!, $remark: String!) {
    unlinkReceipt(receiptID: $receiptID, remark: $remark)
  }
`;

export const GET_ADVANCE_PAYMENT_ITEM_AMOUNT = gql`
  query getAdvancePaymentItemAmountForRegistration(
    $registrationID: Int!
    $registrationChildID: Int!
  ) {
    getAdvancePaymentItemAmountForRegistration(
      registrationID: $registrationID
      registrationChildID: $registrationChildID
    ) {
      itemType
      unitPrice
      totalAmount
    }
  }
`;

export const GET_ECDA_ADVANCE_PAYMENT_ITEM_AMOUNT = gql`
  query getAdvancePaymentItemAmountForEcdaRegistration(
    $registrationID: Int!
    $registrationChildID: Int!
  ) {
    getAdvancePaymentItemAmountForEcdaRegistration(
      registrationID: $registrationID
      registrationChildID: $registrationChildID
    ) {
      itemType
      unitPrice
      totalAmount
    }
  }
`;

export const GET_CHILD_FINANCIAL_ASSISTANCE_STATUS = gql`
  query getChildFinancialAssistanceStatus($centreID: Int!, $childID: Int!) {
    getChildFinancialAssistanceStatus(centreID: $centreID, childID: $childID) {
      msfSUGStatus
      bhfSUGStatus
      referralType
      faCoPaymentAmount
    }
  }
`;

export const GET_CHILD_STATEMENT_OF_ACCOUNT = gql`
  query getChildStatementOfAccountV2(
    $IDChild: Int!
    $year: Int!
    $moveBackwardOneMonth: Boolean
  ) {
    getChildStatementOfAccountV2(
      IDChild: $IDChild
      year: $year
      moveBackwardOneMonth: $moveBackwardOneMonth
    ) {
      history {
        date
        lastCdaDue
        lastNonCdaDue
        lastDue
        cdaDue
        nonCdaDue
        totalDue
        eStatementPdfURL
        paymentStatus
        items {
          documentNo
          documentType
          documentDate
          label
          status
          amount
          url
          outStandingAmount
        }
      }
    }
  }
`;

export const GET_CHILD_SOA_BY_YEAR = gql`
  query getChildSOAByYear($childID: Int!, $year: Int!) {
    getChildSOAByYear(childID: $childID, year: $year) {
      totalCount
      data {
        fkChild
        ID
        fkCentre
        date
        uRL
        cdaOutstanding
        nonCdaOutstanding
        totalOutstanding
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_BILL_REF_NO = gql`
  mutation updateBankAccountBillReferenceNumber(
    $IDBankAccount: Int!
    $referenceNumber: String!
  ) {
    updateBankAccountBillReferenceNumber(
      IDBankAccount: $IDBankAccount
      referenceNumber: $referenceNumber
    ) {
      ID
      billReferenceNumber
    }
  }
`;

export const LIST_BULK_INVOICES = gql`
  query listBulkInvoiceRequest(
    $filter: BulkInvoiceFilter
    $pagination: Pagination
  ) {
    listBulkInvoiceRequest(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        status
        fileURLKey
        preSignedUrl
        remarks
        log
        scheduledAt
        active
        createdAt
        updatedAt
        user {
          ID
          firstname
          lastname
        }
      }
    }
  }
`;

export const GET_BULK_INVOICE_TEMPLATE = gql`
  query {
    getBulkInvoiceTemplate
  }
`;

export const GET_PROGRAM_BILLING_MASTER_FILE = gql`
  query exportProgramBillingMasterFile($forDsp: Boolean!) {
    exportProgramBillingMasterFile(forDsp: $forDsp)
  }
`;

export const GET_PROGRAM_BILLING_UPLOAD_TEMPLATE = gql`
  query exportProgramBillingUploadTemplate($forDsp: Boolean!) {
    exportProgramBillingUploadTemplate(forDsp: $forDsp)
  }
`;

export const CANCEL_BULK_INVOICE = gql`
  mutation cancelBulkInvoiceRequest($IDBulkInvoice: Int!, $remarks: String!) {
    cancelBulkInvoiceRequest(IDBulkInvoice: $IDBulkInvoice, remarks: $remarks) {
      ID
    }
  }
`;

export const VALIDATE_BULK_INVOICE = gql`
  mutation validateBulkInvoiceRequest($filePath: String!) {
    validateBulkInvoiceRequest(filePath: $filePath)
  }
`;

export const SAVE_BULK_INVOICE = gql`
  mutation createBulkInvoiceRequest(
    $filePath: String!
    $scheduledAt: Datetime!
  ) {
    createBulkInvoiceRequest(filePath: $filePath, scheduledAt: $scheduledAt) {
      ID
    }
  }
`;

export const UPLOAD_INVOICE_FILE = gql`
  mutation getBulkInvoiceUploadURL($file: Upload!) {
    getBulkInvoiceUploadURL(file: $file) {
      Url
      Key
      Filename
    }
  }
`;

export const GET_DEBT_WRITE_OFF_REASONS = gql`
  query getDebtWriteOffReasonCodes($schoolID: Int!) {
    getDebtWriteOffReasonCodes(schoolID: $schoolID) {
      data {
        ID
        description
        label
      }
    }
  }
`;

export const UPLOAD_PROGRAM_BILLING_FILE = gql`
  mutation getProgramBillingUploadURL($file: Upload!) {
    getProgramBillingUploadURL(file: $file) {
      Url
      Key
      Filename
    }
  }
`;

export const VALIDATE_PROGRAM_BILLING_REQUEST = gql`
  query validateProgramBillingRequest($filePath: String!) {
    validateProgramBillingRequest(filePath: $filePath)
  }
`;

export const SAVE_PROGRAM_BILLING_REQUEST = gql`
  mutation createProgramBillingRequest($filePath: String!) {
    createProgramBillingRequest(filePath: $filePath) {
      data {
        ID
      }
    }
  }
`;

export const WRITE_OFF_INVOICES = gql`
  mutation writeOffInvoices(
    $requestDTOs: [DebtWriteOffInvoiceRequestDTO!]!
    $requestor: String!
    $approver: String!
    $reason: Int!
    $supportingDocumentFileKey: [String!]!
    $otherReason: String
    $remarks: String
  ) {
    writeOffInvoices(
      requestDTOs: $requestDTOs
      requestor: $requestor
      approver: $approver
      reason: $reason
      supportingDocumentFileKey: $supportingDocumentFileKey
      otherReason: $otherReason
      remarks: $remarks
    ) {
      data {
        ID
      }
    }
  }
`;

export const LIST_PROGRAM_BILLINGS = gql`
  query findAllProgramBillingUpload(
    $financeDocumentFilter: FinanceDocumentFilter
    $pagination: Pagination
  ) {
    findAllProgramBillingUpload(
      financeDocumentFilter: $financeDocumentFilter
      pagination: $pagination
    ) {
      totalCount
      data {
        filename
        uRL
        presignedUrl
        uploadedAt
        user {
          firstname
          lastname
        }
      }
    }
  }
`;

export const TRANSFER_CHILD_BALANCE = gql`
  mutation transferChildBalance($dto: BalanceTransferDto!) {
    transferChildBalance(dto: $dto)
  }
`;

export const TRANSFER_REG_CHILD_BALANCE = gql`
  mutation transferRegistrationChildBalance(
    $dto: RegistrationChildBalanceTransferDto!
  ) {
    transferRegistrationChildBalance(dto: $dto)
  }
`;

export const ADJUST_INVOICE_AMOUNT_ITEMS = gql`
  mutation adjustInvoiceAmountByInvoiceItems(
    $invoiceID: Int!
    $dto: [AdjustedInvoiceItemDto!]
    $remark: String!
  ) {
    adjustInvoiceAmountByInvoiceItems(
      invoiceID: $invoiceID
      dto: $dto
      remark: $remark
    )
  }
`;

export const ADJUST_INVOICE_AMOUNT_ITEMS_PREVIEW = gql`
  query adjustInvoiceAmountByInvoiceItemsPreview(
    $invoiceID: Int!
    $dto: [AdjustedInvoiceItemDto!]
  ) {
    adjustInvoiceAmountByInvoiceItemsPreview(invoiceID: $invoiceID, dto: $dto) {
      data {
        cdnAmount
        isCreditNote
      }
    }
  }
`;

export const UPDATE_REFUND_STATUS = gql`
  mutation updateRefundStatus(
    $refundChildBalanceID: Int!
    $newStatus: RefundChildBalanceStatus!
    $cancellationReason: String
  ) {
    updateRefundStatus(
      refundChildBalanceID: $refundChildBalanceID
      newStatus: $newStatus
      cancellationReason: $cancellationReason
    ) {
      ID
    }
  }
`;

export const SKIP_GIRO_REG = gql`
  mutation skipGiroForRegistration($IDRegistration: Int!) {
    skipGiroForRegistration(IDRegistration: $IDRegistration)
  }
`;

export const SKIP_PAYMENT_REG = gql`
  mutation skipRegistrationPaymentStep($IDRegistration: Int!) {
    skipRegistrationPaymentStep(IDRegistration: $IDRegistration)
  }
`;

export const SKIP_ECDA_PAYMENT_REG = gql`
  mutation skipEcdaRegistrationPaymentStep($IDEcdaRegistration: Int!) {
    skipEcdaRegistrationPaymentStep(IDEcdaRegistration: $IDEcdaRegistration)
  }
`;

export const CANCEL_ADVANCE_PAYMENT_RECEIPT = gql`
  mutation cancelAdvancePaymentReceipt($receiptID: Int!, $remark: String!) {
    cancelAdvancePaymentReceipt(receiptID: $receiptID, remark: $remark)
  }
`;

export const WRITE_OFF_CREDIT = gql`
  mutation writeOffCreditBalance($dto: CreditWriteOffDto!) {
    writeOffCreditBalance(dto: $dto)
  }
`;

export const UPDATE_CHILD_FINANCIAL_ASSISTANCE = gql`
  mutation updateChildFinancialAssistanceStatus(
    $centreID: Int!
    $childID: Int!
    $dto: ChildFinancialAssistanceUpdateDTO!
  ) {
    updateChildFinancialAssistanceStatus(
      centreID: $centreID
      childID: $childID
      dto: $dto
    )
  }
`;

export const UPDATE_CHILD_TRADE_DEBT_NOTIFY = gql`
  mutation updateChildTradeDebtNotificationConfig(
    $childID: Int!
    $notificationEnabled: Boolean!
  ) {
    updateChildTradeDebtNotificationConfig(
      childID: $childID
      notificationEnabled: $notificationEnabled
    )
  }
`;

export const TRADE_DEBT_NOTIFICATIONS = gql`
  query getTradeDebtNotifications($childID: Int!) {
    getTradeDebtNotifications(childID: $childID) {
      notificationType
      centreName
      dateTime
      subject
      outstandingAmount
      emailURL
      preSignedURL
    }
  }
`;

export const MARK_CHECKED_REFUND = gql`
  mutation markChildToGenerateGiroRefund(
    $isSelectAll: Boolean
    $IDRefundChildBalance: [Int!]
  ) {
    markChildToGenerateGiroRefund(
      isSelectAll: $isSelectAll
      IDRefundChildBalance: $IDRefundChildBalance
    )
  }
`;

export const UNMARK_CHECKED_REFUND = gql`
  mutation unMarkChildToGenerateGiroRefund(
    $isSelectAll: Boolean
    $IDRefundChildBalance: [Int!]
  ) {
    unMarkChildToGenerateGiroRefund(
      isSelectAll: $isSelectAll
      IDRefundChildBalance: $IDRefundChildBalance
    )
  }
`;

export const CHECKED_REFUND_COUNTS = gql`
  query getCurrentChildRefundStatus {
    getCurrentChildRefundStatus {
      TotalRefundSelected
      TotalRefundSelectedAmount
    }
  }
`;

export const CANCEL_EXPORT_GIRO_REFUND = gql`
  mutation cancelExportGiroRefund($docId: Int!) {
    cancelExportGiroRefund(docId: $docId)
  }
`;

export const FIND_ALL_VALID_DOWNLOADED_GIRO_FILES = gql`
  query findAllValidDownloadedGiroFiles(
    $at: Datetime!
    $pagination: Pagination
  ) {
    findAllValidDownloadedGiroFiles(at: $at, pagination: $pagination) {
      totalCount
      data {
        ID
        label
        uRL
        status
        giroStatus
        log
        docType
        presignedUrl
        user {
          ID
          firstname
          lastname
        }
        createdAt
        uploadedAt
        fkFinanceDocument
        financeDocuments(pagination: { sort: "-createdAt" }) {
          data {
            ID
            label
            uRL
            status
            log
            docType
            presignedUrl
            errorLog
            createdAt
            uploadedAt
            user {
              ID
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`;

export const FINANCE_DOC_PDF_URL = gql`
  mutation getFinanceDocumentPdfPresignedURL(
    $documentID: Int!
    $documentType: String!
  ) {
    getFinanceDocumentPdfPresignedURL(
      documentID: $documentID
      documentType: $documentType
    )
  }
`;

export const GIRO_SIBLING_SELECTION_ACTION = gql`
  mutation giroSiblingSelectionAction(
    $IsSiblingCda: Boolean!
    $IDRegChild: Int
    $IDEcdaRegChild: Int
    $IDChild: Int
  ) {
    giroSiblingSelectionAction(
      IsSiblingCda: $IsSiblingCda
      IDRegChild: $IDRegChild
      IDEcdaRegChild: $IDEcdaRegChild
      IDChild: $IDChild
    )
  }
`;

export const SAVE_GIRO_SIBLING_DETAILS = gql`
  mutation saveGiroSiblingDetails(
    $IDRegChild: Int
    $IDChild: Int
    $IDEcdaRegChild: Int
    $sibling: Sibling!
  ) {
    saveGiroSiblingDetails(
      IDRegChild: $IDRegChild
      IDChild: $IDChild
      IDEcdaRegChild: $IDEcdaRegChild
      sibling: $sibling
    )
  }
`;
